import * as Yup from 'yup'

export const editProposalSchema = Yup.object().shape({
    proposalTitle: Yup.string().required('Required field').min(3, 'Must be 3 at least 3 characters long'),
    customerFirstName: Yup.string().required('Required field'),
    customerLastName: Yup.string().required('Required field')
})

export interface EditProposalInterface {
    proposalTitle: string | null | undefined;
    customerName: string | null | undefined;
    proposalIntro: string | null | undefined;

}

export default editProposalSchema

