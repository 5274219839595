export default [
  {
    link: "https://docs.google.com/document/d/1KI7fGTBQS6i9iL2zqxgwu0YakFxAWc6cLr5eBa7QtM4/edit",
    colourCode: '#3AB2E8',
    id: '1',
    name: 'CUSTOMER INFORMATION',
  },
  {
    link: null,
    colourCode: '#88B3CB',
    id: '2',
    name: 'DEPLOYING AT SCALE',
  },
  {
    link: "https://prosupport.logi.com/hc/en-us",
    colourCode: '#FC912C',
    id: '3',
    name: 'PRO SUPPORT',
  },
  {
    link: "https://www.logitech.com/en-gb/video-collaboration/products/sync.html",
    colourCode: '#0A5677',
    id: '4',
    name: 'MANAGEMENT OF DEVICES',
  },
  {
    link: "https://www.logitech.com/en-gb/social-impact/diversity-inclusion.html",
    colourCode: '#FC912C',
    id: '5',
    name: 'DIVERSITY',
  },
  {
    link: null,
    colourCode: '#0A5677',
    id: '6',
    name: 'CONTACT YOUR ACCOUNT TEAM',
  },
  {
    link: "https://www.logitech.com/content/dam/logitech/en/sustainability/pdf/resources/sustainability-report-fy21-aw-spreads.pdf",
    colourCode: '#3AB2E8',
    id: '7',
    name: 'SUSTAINABILITY',
  },
  {
    link: null,
    colourCode: '#88B3CB',
    id: '8',
    name: 'COMMERCIAL OFFERING',
  },
];
