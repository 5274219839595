/**
 *
 * InlineWysiwygField
 *
 */

import React, {useEffect, useState} from 'react';
import {Props} from "./types";
import {StyleWrapper} from './styles'
import {FormattedMessage} from 'react-intl';
import messages from './messages';
import {Editor} from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {EditorState, ContentState, convertToRaw, convertFromHTML} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
import DOMPurify from 'dompurify';
import {Field, FormikErrors, useFormikContext} from 'formik';

const InlineWysiwygField = ({editMode, value, name}: Props) => {
    const [editState, setEditState] = useState(EditorState.createEmpty());
    const [contentState, setContentState] = useState<ContentState>(ContentState.createFromText(""));
    const {values, setFieldValue} = useFormikContext();

    const onEditorStateChange = (editorState: any) => {
        // @ts-ignore
        setEditState(editorState);
        // update field value
        // @ts-ignore
        if (!!values?.[name]) {
            // @ts-ignore
            setFieldValue(name, draftToHtml(convertToRaw(editState.getCurrentContent())))
            console.log(draftToHtml(convertToRaw(editState.getCurrentContent())))
        }

    };


    useEffect(() => {
        // @ts-ignore
        if (!!values?.[name]) {
            // @ts-ignore
            const blocksFromHTML = convertFromHTML(values?.[name]);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );

            setEditState(EditorState.createWithContent(state))

            // setContentState(ContentState.createFromText(value));
        }
    }, [])


    if (!!editMode) {
        return <StyleWrapper>
            <Editor
                editorState={editState}
                onEditorStateChange={onEditorStateChange}
                // @ts-ignore
                contentState={contentState}
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign','link'],
                }
                }
            />
        </StyleWrapper>
    }

    // use Dompurify to sanitize the inner html
    return <StyleWrapper>
        <div dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
                draftToHtml(
                    convertToRaw(
                        editState.getCurrentContent()
                    )
                )
            )
        }}/>
    </StyleWrapper>
}


export default InlineWysiwygField;
