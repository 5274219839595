import { gql } from "@apollo/client";

export const SEARCH_CLIENTS = gql`
    query SearchClients($searchClientsSearchTerm: String) {
        searchClients(searchTerm: $searchClientsSearchTerm) {
            client {
                id
                name
                organisation
                role
                phoneNumber
                jobTitle
                emailAddress: email
            }
            actions {
                canEdit
                canDelete
                canResetPassword
            }
        }
    }
`;
