/**
 *
 * ProposalContent
 *
 */

import styled from 'styled-components';
import { mediaQueries, fonts } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  margin: 0 auto;
  width: 740px;
  max-width: 1314px;
  height: 74px;
  display: flex;
  flex-direction: column;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
    grid-template-columns: repeat(4, 1fr);
  }

  ${mediaQueries.desktop} {
    width: 1314px;
  }
`;

export const FlexRow = styled.div<{
  height?: string;
  flex?: number;
  justify?: string;
}>`
  display: flex;
  align-items: center;
  height: ${(props) => props.height};
  flex: ${(props) => props.flex};
  justify-content: ${(props) => props.justify};
`;

export const Text = styled.div<{ fontSize?: string }>`
  font-size: ${(props) => props.fontSize || '20px'};
  font-family: ${fonts.bold};
  color: ${(props) => props.color || 'black'};
`;

export const Pencil = styled.div`
  cursor: pointer;
  width: 34px;
  height: 29px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33.407' height='28.366' viewBox='0 0 33.407 28.366'%3E%3Cpath id='_253708480' d='M294.036,306.888l-.792-1.3,7.677-4.823.792,1.3a3.084,3.084,0,0,1-.977,4.207l-2.559,1.607A2.984,2.984,0,0,1,294.036,306.888Zm-9.007-17.7a.745.745,0,0,0-1.035-.248.768.768,0,0,0-.244,1.051l8.7,14.295,1.28-.8-8.7-14.293Zm6.4-4.019a.745.745,0,0,0-1.035-.248.77.77,0,0,0-.244,1.051l8.7,14.295,1.279-.8Zm6.146,15.9-8.705-14.294a1.492,1.492,0,0,0-2.071-.5,1.539,1.539,0,0,0-.487,2.1l8.7,14.293Zm-14.367-13.431a2.167,2.167,0,0,1,1.39-.291,3.169,3.169,0,0,1,.062-.47,3.034,3.034,0,0,1,1.347-1.9,2.817,2.817,0,0,1,2.684-.233,2.257,2.257,0,0,1,.911-1.126c.041-.025.088-.027.131-.049L283,280.049l.1,7.673c.036-.025.066-.056.1-.08Z' transform='translate(-146.464 395.666) rotate(-112)' fill='%23393b3a'/%3E%3C/svg%3E%0A");
`;
