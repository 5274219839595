/**
 *
 * ContentManagementContainer
 *
 */
import React from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

interface ContentManagementContainerProps {}

const ContentManagementContainer = ({}: ContentManagementContainerProps) => {
  return (
    <>
      <FormattedMessage {...messages.header} />
    </>
  );
};

export default ContentManagementContainer;
