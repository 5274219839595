/**
 *
 * Logo
 *
 */

import styled from 'styled-components';
import { StyleWrapperProps } from './types';

export const StyleWrapper = styled.div<StyleWrapperProps>`
  width: ${(props) => props.width || '243px'};
  height: ${(props) => props.height || '73px'};
  margin-top: ${(props) => props.marginTop};
  flex-shrink: 0;
  cursor: pointer;
`;
