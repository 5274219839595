/**
 *
 * GrahpQlProof
 *
 */

import React from "react";
import { Props } from "./types";
import { StyleWrapper } from "./styles";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { useQuery } from "@apollo/client";
import { GET_ALL_PROPOSALS } from "../../utils/gql/example/getAllProposals";
import { GetAllProposals } from "../../utils/gql/example/__generated__/GetAllProposals";

function GrahpQlProof({}: Props) {
  const { loading, error, data } = useQuery<GetAllProposals>(GET_ALL_PROPOSALS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <StyleWrapper>
      <FormattedMessage {...messages.header} />
      {/*// @ts-ignore*/}
      {/*{data.rates.filter((_, index) => index < 3).map(({currency, rate}) => (*/}
      {/*    <div key={currency}>*/}
      {/*        <p>*/}
      {/*            {currency}: {rate}*/}
      {/*        </p>*/}
      {/*    </div>*/}
      {/*))}*/}
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </StyleWrapper>
  );
}

export default GrahpQlProof;
