/**
 *
 * BrickSelector
 *
 */

import styled from 'styled-components';
import { ItemProps, TitleProps } from './types';
import { mediaQueries } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  padding-bottom: 87px;
  position: relative;
  background: white;
  margin-top: 64px;
`;

export const Container = styled.div`
  width: 740px;
  height: auto;
  display: flex;
  flex-direction: column;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
    grid-template-columns: repeat(4, 1fr);
  }

  ${mediaQueries.desktop} {
    width: 1314px;
  }
`;

export const Header = styled.div`
  margin-top: 70px;
  height: auto;
  font-size: 31px;
  text-transform: uppercase;
  color: #2e3132;

  ${mediaQueries.desktop} {
    font-size: 61px;
  }
`;

export const SubHeader = styled.div`
  margin-top: 19px;
  font-size: 18px;
  height: 22px;
  color: #2e3132;
`;

export const Grid = styled.div`
  margin: 15px auto 0;
  width: 740px;
  max-width: 1314px;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 14px;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
    grid-template-columns: repeat(4, 1fr);
  }

  ${mediaQueries.desktop} {
    width: 1314px;
  }
`;

export const Item = styled.div<ItemProps>`
  position: relative;
  max-width: 318px;
  height: 180px;
  border-radius: 6px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.background};
`;

export const CheckBox = styled.input`
  all: unset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: absolute;
  top: 13px;
  left: 13px;
  width: 28px;
  height: 28px;
  background: white;
  border-radius: 5px;
  cursor: pointer;

  &:checked {
    background-image: url("data:image/svg+xml,%3Csvg id='Group_24749' data-name='Group 24749' xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Crect id='Rectangle_18' data-name='Rectangle 18' width='28' height='28' rx='5' fill='%2300b8fc'/%3E%3Cpath id='Path_3' data-name='Path 3' d='M5.36,14l6.5,5.869L22.437,6.475' fill='none' stroke='%23fff' stroke-width='4'/%3E%3C/svg%3E%0A");
  }
`;

export const Title = styled.div<TitleProps>`
  color: ${(props) => props.color || 'white'};
  font-size: 16px;
  text-align: center;
  max-height: 68px;
  height: auto;
  font-family: 'BrownProTTBold';

  ${mediaQueries.desktop} {
    font-size: 31px;
  }
`;

export const Close = styled.div`
  position: absolute;
  top: 46px;
  right: 43px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36.592' height='36.581' viewBox='0 0 36.592 36.581'%3E%3Cpath id='Path' d='M.469,35.216a1.375,1.375,0,0,0,1.025.45,1.4,1.4,0,0,0,1.125-.45L17.847,19.981,33.024,35.216a1.4,1.4,0,0,0,1.05.45,1.375,1.375,0,0,0,1.05-.45,1.426,1.426,0,0,0,0-2.076L19.9,17.88,35.125,2.7A1.476,1.476,0,1,0,33.049.62L17.8,15.854,2.62.545A1.476,1.476,0,1,0,.544,2.621L15.772,17.855.544,33.114a1.426,1.426,0,0,0,0,2.076' transform='translate(0.461 0.461)' fill='%2300b8fc' stroke='%23fff' stroke-miterlimit='10' stroke-width='0.9'/%3E%3C/svg%3E%0A");
`;
