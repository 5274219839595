/**
 *
 * TableProof
 *
 */

import React from 'react';
import {Props} from './types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {StyledTableCell, useStyles} from './styles'

export default function CustomizedTables({headers, tableData, renderBody}: Props) {
    const classes = useStyles();

    const renderTableBody = () => {
        if(tableData.length){
            return tableData.map((row, index) => renderBody(row, index));
        }
        return [];
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {headers.map((header, index) => (
                            <StyledTableCell key={index}>{header.title}</StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderTableBody()}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
