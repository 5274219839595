/**
 *
 * TableProofContainer
 *
 */
 import React, { useEffect, useState } from 'react';
 import {
   Container,
   Grid,
   IconButton,
   InputLabel as Label,
   Box,
   SvgIcon
 } from '@material-ui/core'
 import {Title, StyledTableCell, StyledTableRow, StyledButton} from './styles'
 import Table from '../../components/TableProof';
 import Button from '../../components/Button';
 import StyledModal from '../../components/StyledModal';
 import Asynchronous from '../../components/FormFields/AsyncSelectField';
 import CreateOrganisationForm from '../../components/Forms/CreateOrganisation';
 import {ReactComponent as Edit} from '../../images/svg/EditIcon.svg'
 import {ReactComponent as Delete} from '../../images/svg/DeleteIcon.svg'
 import {ReactComponent as Lock} from '../../images/svg/LockIcon.svg'
 import FormikHandler from '../../components/FormikHandler'
import ConfirmCancelModal from '../../components/ConfirmCancelModal';
import { useHistory } from 'react-router';
 
 const OrganisationsContainer = () => {

  const {location}: any = useHistory()

  const generateHeaders = () => {
     return [
       {title: "Logo"},
       {title: "Organisation"},
       {title: "Domain(s)"},
       {title: "Region"},
       {title: ""}
     ]
   }
 
   const generateRows = () => {
     return [
       {
         logo: "Some random name",
         organisation: "The Virtual Forge",
         domains: ["thevirtualforge.com"],
         region: "South East",
         actions: [
           {type: "Edit", component: Edit},
           {type: "Delete", component: Delete},
         ]
       },
       {
        logo: "Some random name",
        organisation: "The Virtual Forge",
        domains: ["thevirtualforge.com"],
        region: "South East",
        actions: [
          {type: "Edit", component: Edit},
          {type: "Delete", component: Delete},
        ]
      },
    ]
   }
 
   const availableActions = (actionType: string) => {
     const actions = [
       {type: "Delete", action: setDeleteOrganisationOpen, value: deleteOrganisationOpen}
     ]
     const action = actions.find((item) => item.type === actionType)
     return action?.action(!action.value)
   }
 
   const [isOpen, setIsOpen] = useState<boolean>(false)
   const [deleteOrganisationOpen, setDeleteOrganisationOpen] = useState<boolean>(false)
   const [resetPasswordOpen, setResetPasswordOpen] = useState<boolean>(false)

   useEffect(() => {
    if(location.state?.openModal){
      setIsOpen(true)
    }
  }, [location.state])
 
   return (
     <Container component={Box} sx={{mt: 2}}>
       <StyledModal onRequestClose={() => setIsOpen(false)} isOpen={isOpen}>
         <CreateOrganisationForm onClickCancel={() => setIsOpen(false)} />
       </StyledModal>
       <ConfirmCancelModal
          title="Delete client?"
          subtitle="Are you sure you want to mark this client for deletion?"
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Yes I'm sure"
          onRequestClose={() => setDeleteOrganisationOpen(false)}
          onRequestConfirm={() => console.log("deletion in progress")}
          isOpenConfirmCancel={deleteOrganisationOpen}
       />
       <ConfirmCancelModal
          title="Reset password?"
          subtitle="Are you sure you want to reset this users password?"
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Yes I'm sure"
          onRequestClose={() => setResetPasswordOpen(false)}
          onRequestConfirm={() => console.log("deletion in progress")}
          isOpenConfirmCancel={resetPasswordOpen}
       />       
       <Grid container spacing={1}>
         <Grid item xs={8}>
           <Title variant="h2">Organisation management</Title>
         </Grid>
         <Grid item xs={4}>
           <StyledButton onClick={() => setIsOpen(!isOpen)}>Create organisation</StyledButton>
         </Grid>
         <Grid item xs={12}>
             <FormikHandler
                name="emails"
                initialValues={{emails: []}}
                handleSubmit={(values: any) => console.log(values)}
                placeholder="Search by Organisations"
            />
         </Grid>
         <Grid item xs={12}>
           <Table
             headers={generateHeaders()}
             tableData={generateRows()}
             renderBody={(row, index) => (
               <StyledTableRow key={index}>
                 {Object.keys(row).filter(key => key !== "actions").map((rowProperty, index) => (
                    <>
                     <StyledTableCell key={`org-table-${index}`}>{row[rowProperty]}</StyledTableCell>
                    </>
                 ))}
                 <StyledTableCell>
                   {row.actions.map((action: {type: string, component: any}) => (
                     <IconButton onClick={() => availableActions(action.type)}>
                       <SvgIcon component={action.component} viewBox="0 0 31 31" />
                     </IconButton>
                   ))}
                 </StyledTableCell>
               </StyledTableRow>
             )}
           />
         </Grid>
         <Grid component={Box} sx={{mt: 1, mb: 2}} item xs={12}>
           <Grid container alignItems="flex-end" justifyContent="flex-end">
              <Button onClick={() => setIsOpen(!isOpen)}>Create organisation</Button>
           </Grid>
         </Grid>
       </Grid>
     </Container>
   );
 }
 
 export default OrganisationsContainer;
 