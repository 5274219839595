import { useState } from 'react';

const move = (array: any, oldIndex: number, newIndex: number) => {
  if (newIndex >= array.length) {
    newIndex = array.length - 1;
  }

  let newArr = [...array];

  let oldItem = { ...array[oldIndex] };
  let newItem = { ...array[newIndex] };

  oldItem.colourCode = array[newIndex].colourCode;
  newItem.colourCode = array[oldIndex].colourCode;

  newArr[oldIndex] = newItem;
  newArr[newIndex] = oldItem;

  return newArr;
};

const moveElement = (array: any, index: number, offset: number) => {
  const newIndex = index + offset;
  return move(array, index, newIndex);
};

const useGridState = () => {
  const [gridState, setState] = useState<GridState>({
    items: [],
  });

  const initGrid = (bricks: any) => {
    setState({ items: bricks });
  };

  const moveItem = (sourceId: number, destinationId: number) => {
    const sourceIndex = gridState.items?.findIndex(
      (item: any) => item.id === sourceId
    );

    const destinationIndex = gridState.items?.findIndex(
      (item: any) => item.id === destinationId
    );

    // If source/destination is unknown, do nothing.
    if (sourceId === -1 || destinationId === -1) {
      return;
    }

    const offset = destinationIndex - sourceIndex;

    setState((prevState) => ({
      items: moveElement(prevState.items, sourceIndex, offset),
    }));
  };

  const returnSortOrder = () => gridState?.items?.map((el) => el.id);

  return { gridState, initGrid, setState, moveItem };
};

export default useGridState;
