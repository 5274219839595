/**
 *
 * SaveExit
 *
 */

import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

import ButtonHover from '../ButtonHover';

const SaveExit: FC = () => {
  return (
    <ButtonHover
      label='SAVE & EXIT'
      backgroundHover='#2E3132'
      backgroundStart='#fff'
      colorHover='#fff'
      colorStart='#2E3132'
      width='254px'
    >
      <FormattedMessage {...messages.saveExit} />
    </ButtonHover>
  );
};

export default SaveExit;
