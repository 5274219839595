import {
    createStyles,
    makeStyles,
    useTheme,
    Theme,
  } from "@material-ui/core/styles";

  export const fontColor = '#393b3a';
  export const grey = '#E1E2E3';

  export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            width: "100%"
        },
        formStyle: {
            maxWidth: "1193px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            margin: "20px",
        },
        formTitle: {
            color: fontColor,
            fontSize: 32,
            textTransform: "uppercase",
            fontWeight: "bold"
        },
        inputLabel: {
            color: fontColor,
            fontSize: 18,
            fontWeight: "bold"
        },
        gridLine: {
            border: "1px solid red"
        },
        fileDrop: {
            border: `1px solid ${grey}`,
            display: "flex",
            flexGrow: 1
        },
        imageIcon: {
            display: "flex",
            height: "100%",
        },
        iconRoot: {
            textAlign: "center",
            border: "1px solid red"
        }
    })
  )
