/**
 *
 * MultiSelect
 *
 */
// @ts-nocheck
import React, {useMemo, useState} from 'react';
import {Props} from './types';
import {StyleWrapper, ArrowContainer, Arrow, StyledOption} from './styles';

import {components, StylesConfig, OptionTypeBase} from 'react-select';

import CreatableSelect from 'react-select/creatable';

export const grey = '#E1E2E3';
export const blue = '#265FC5';


const MultiSelect = ({addNew = false, placeholder = "", options = []}: Props) => {
    const customStyles: StylesConfig<OptionTypeBase, false> = {
        control: (base) => ({
            ...base,
            boxSizing: 'border-box',
            borderRadius: '5px',
            outline: 0,
            height: 72,
            border: `2px solid ${grey}`,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '&:hover': {
                borderColor: `${blue}`,
            },
        }),
        input: (base) => ({
            ...base,
            border: 0,
            outline: 0,
            padding: 0,

        }),
        valueContainer: (base) => ({
            ...base,
            height: 72,
            maxHeight: 72,
            flexWrap: 'nowrap',
            paddingLeft: 30,
            paddingRight: 30,
            color: "#707070",
        }),

        menu: (base) => ({
            ...base,
            border: `2px solid ${grey}`,
            boxShadow: 'none',
        }),

        menuList: (base) => ({
            ...base,
            marginTop: 0,
            padding: 0,
            color: '#707070',
            borderRadius: '5px',
            overflowX: 'hidden',
        }),
    };

    const [menu, setMenu] = useState<boolean>(false);

    const IndicatorsContainer = (props: any) => (
        <components.IndicatorsContainer {...props}>
            <ArrowContainer>
                <Arrow transform={menu ? 'rotate(180deg)' : 'rotate(0deg)'}/>
            </ArrowContainer>
        </components.IndicatorsContainer>
    );

    const CustomOption = (props) => {
        const {innerRef, innerProps, data} = props;

        return (
            <StyledOption ref={innerRef} {...innerProps}>
                {data?.label}
            </StyledOption>
        );
    };

    const SingleValue = (props) => {
        const {innerRef, innerProps, data} = props;

        const {label} = data;

        return (
            <div ref={innerRef} {...innerProps}>
                {label === 'Add New' ? '' : label}
            </div>
        );
    };

    const menuOptions = useMemo(
        () => {
            if (addNew === true) return [...options, {label: 'Add New'}];

            return options;
        },
        [addNew, options],
    );

    return (
        <StyleWrapper>
            <CreatableSelect
                styles={customStyles}
                options={menuOptions}
                placeholder={placeholder}
                components={{
                    IndicatorsContainer,
                    IndicatorSeparator: () => null,
                    Option: CustomOption,
                    SingleValue,
                }}
                theme={(theme: any) => ({
                    ...theme,
                    borderRadius: '5px',
                    outline: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#F8F8F8',
                    },
                })}
                onMenuOpen={() => setMenu(true)}
                onMenuClose={() => setMenu(false)}
                onChange={({value}) => console.log(value)}
            />
        </StyleWrapper>
    );
};

export default MultiSelect;
