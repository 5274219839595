import React from 'react'
import {useStyles} from './styles'
import {Grid, Typography, InputLabel, MenuItem, InputAdornment, SvgIcon} from '@material-ui/core'
import OrganisationSchema, {initialValues, CreateOrgInterface, regions} from './formik'
import { Form, Formik, FormikProps, Field, FormikValues } from "formik";
import TextField from '../../FormFields/TextField'
import SelectField from '../../FormFields/SelectField';
import Button from '../../Button'
import FileDrop from '../../FileDrop';

interface Props {
    onClickCancel: () => void
}

const CreateOrganisationForm = ({onClickCancel}: Props) => {
    const classes = useStyles();

    const handleSubmit = async (arg: any) => {
      alert(JSON.stringify(arg));
    };

    return (
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    // @ts-ignore
                    validationSchema={OrganisationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        isValid,
                        errors,
                        touched,
                        setFieldValue
                    }: FormikProps<CreateOrgInterface>) => (
                        <Form autoComplete="off" noValidate className={classes.formStyle}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography className={classes.formTitle}>Create organisation</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel className={classes.inputLabel}>Organisation name</InputLabel>
                                    <TextField
                                        name="organisationName"
                                        onBlur={handleBlur}
                                        variant="outlined"
                                        onChange={handleChange}
                                        value={values.organisationName}
                                        marginTop="10px"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <InputLabel className={classes.inputLabel}>Organisation domain(s)</InputLabel>
                                            <TextField
                                                name="organisationDomains"
                                                onBlur={handleBlur}
                                                variant="outlined"
                                                onChange={handleChange}
                                                value={values.organisationDomains}
                                                marginTop="10px"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel className={classes.inputLabel}>Region</InputLabel>
                                            {/* <Asynchronous name="emails" /> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid alignItems="center" justifyContent="center" container spacing={3}>
                                        <Grid item xs={12}>
                                            <InputLabel className={classes.inputLabel}>Logo</InputLabel>
                                            <Field
                                                height="199px"
                                                enablePreview
                                                as={FileDrop}
                                                name="organisationLogo"
                                                id="organisationLogo"
                                                htmlFor="organisationLogo"
                                                value={values.organisationLogo}
                                                onChange={(event: any) =>  {
                                                    setFieldValue("organisationLogo", event.target.files[0])
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={5}>
                                        <Grid item xs={3}>
                                            <Button className={classes.noHoverButton} onClick={onClickCancel}>Cancel</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button className={classes.button}>Create Organisation</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
    )
}

export default CreateOrganisationForm
