/**
 *
 * ProofOfContext
 *
 */

import React, { useContext } from "react";
import { Props } from "./types";
import { StyleWrapper } from "./styles";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { ExampleContext } from "../../context/ExampleContext";

function ProofOfContext({}: Props) {
  const { counter, updateCounter } = useContext(
    ExampleContext
  ) as ExampleContextType;

  return (
    <StyleWrapper>
      <FormattedMessage {...messages.header} />
      <h1>{counter}</h1>
      <button onClick={() => updateCounter(counter + 1)}>
        <FormattedMessage {...messages.udpate} />
      </button>
    </StyleWrapper>
  );
}

export default ProofOfContext;
