import React from 'react'
import {Grid, Typography, InputLabel, Box, Divider, InputAdornment, SvgIcon} from '@material-ui/core'
import Button from '../../Button'
import {useStyles} from './styles'
import {Formik, FormikProps, Form} from 'formik'
import TextField from '../../FormFields/TextField'
import {initialValues, createClientSchema, CreateClientInterface} from './formik'
import {ReactComponent as SearchIcon} from '../../../images/svg/SearchIcon.svg'

interface Props {
    onClickCancel?: () => void | undefined
    startCreateNewOrganisation?: () => void | undefined
}

const CreateClientForm = ({onClickCancel, startCreateNewOrganisation}: Props) => {
    const classes = useStyles()

    const handleSubmit = async (arg: any) => {
      alert(JSON.stringify(arg));
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            // @ts-ignore
            validationSchema={createClientSchema}
            onSubmit={handleSubmit}
        >
            {({
                values,
                handleChange,
                handleBlur,
                isSubmitting,
                isValid,
                errors,
                touched,
                setFieldValue
            }: FormikProps<CreateClientInterface>) => (
                <Form autoComplete="off" noValidate className={classes.formStyle}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography className={classes.formTitle}>Create client</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <InputLabel className={classes.inputLabel}>Organisation</InputLabel>
                                <TextField
                                    name="organisationName"
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={values.organisationName}
                                    marginTop="10px"
                                />
                        </Grid>
                        <Grid component={Box} sx={{mt: 3.25}} item xs={3}>
                            <Button onClick={startCreateNewOrganisation} className={classes.button}>Create new organisation</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel className={classes.inputLabel}>First Name</InputLabel>
                            <TextField
                                name="firstName"
                                onBlur={handleBlur}
                                variant="outlined"
                                onChange={handleChange}
                                value={values.firstName}
                                marginTop="10px"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel className={classes.inputLabel}>Surname</InputLabel>
                            <TextField
                                name="surname"
                                onBlur={handleBlur}
                                variant="outlined"
                                onChange={handleChange}
                                value={values.surname}
                                marginTop="10px"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel className={classes.inputLabel}>Email address</InputLabel>
                            <TextField
                                name="email"
                                onBlur={handleBlur}
                                variant="outlined"
                                onChange={handleChange}
                                value={values.email}
                                marginTop="10px"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel className={classes.inputLabel}>Optional fields:</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <hr />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel className={classes.inputLabel}>Phone number</InputLabel>
                            <TextField
                                name="phoneNumber"
                                onBlur={handleBlur}
                                variant="outlined"
                                onChange={handleChange}
                                value={values.phoneNumber}
                                marginTop="10px"
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <InputLabel className={classes.inputLabel}>Job Title</InputLabel>
                            <TextField
                                name="jobTitle"
                                onBlur={handleBlur}
                                variant="outlined"
                                onChange={handleChange}
                                value={values.jobTitle}
                                marginTop="10px"
                            />
                        </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
                                        <Grid item xs={3}>
                                            <Button className={classes.noHoverButton} onClick={onClickCancel}>Cancel</Button>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button className={classes.button}>Create new organisation</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>                        
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default CreateClientForm