/**
*
* CustomTooltip
*
*/

import React, { useRef } from 'react';
import { Props } from "./types";
import { StyleWrapper, StyledTooltip } from './styles'

function CustomTooltip({children, title, onClose, open}: Props) {
  const ref = useRef(null)
  return (
      <StyledTooltip
        PopperProps={{
          disablePortal: false,
        }}
        ref={ref}
        arrow
        title={title}
        onClose={onClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        interactive
        placement="bottom-end"
      >
        {children}
      </StyledTooltip>
  );
}

export default CustomTooltip;