import React, { useContext } from 'react';
import { GridItemWrapper, Container, Add, Title } from './styles';

import ProposalContext from '../../containers/ViewProposalContainer/propsalContext';
import { ProposalI } from '../../containers/ViewProposalContainer/types';

const AddProposal: React.FC = () => {
  const proposalContext = useContext(ProposalContext) as ProposalI;
  const { brickSelector, setBrickSelector } = proposalContext;

  return (
    <GridItemWrapper style={{ border: '3px solid grey' }}>
      <Container>
        <Add onClick={() => setBrickSelector(!brickSelector)} />
        <Title color='#707070'>ADD NEW PROPOSAL BRICK</Title>
      </Container>
    </GridItemWrapper>
  );
};

export default AddProposal;
