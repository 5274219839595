import styled from 'styled-components';
import { motion } from 'framer-motion';
import { mediaQueries } from '../../utils/styledTheme';

export const Grid = styled.div`
  margin: 0 auto;
  width: 740px;
  max-width: 1314px;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 14px;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
    grid-template-columns: repeat(4, 1fr);
  }

  ${mediaQueries.desktop} {
    width: 1314px;
  }
`;

export const GridImage = styled(motion.div)<GridImgProps>`
  flex-grow: 1;
  border-radius: 6px;
  background: ${(props) => props.background};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div<TitleProps>`
  color: ${(props) => props.color || 'white'};
  font-size: 22px;
  text-align: center;
  max-height: 68px;
  height: auto;
  font-family: 'BrownProTTBold';

  ${mediaQueries.desktop} {
    font-size: 31px;
  }
`;

export const GridItemWrapper = styled.div`
  position: relative;
  max-width: 318px;
  height: 180px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: stretch;
`;

export const Drag = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.261' height='29.261' viewBox='0 0 29.261 29.261'%3E%3Cg id='Group_1861' data-name='Group 1861' transform='translate(0 0)'%3E%3Cg id='move'%3E%3Cg id='Group_1112' data-name='Group 1112' transform='translate(14.63 0) rotate(45)'%3E%3Crect id='icon_light_switching_background' data-name='icon/light/switching background' width='20.691' height='20.691' fill='none'/%3E%3Cpath id='Combined_Shape' data-name='Combined Shape' d='M11.68,18.166V16.872h4.257l-5.6-5.6.914-.914,5.6,5.6V11.7h1.293v6.466ZM0,18.166V11.7H1.293v4.26l5.6-5.6.914.915-5.6,5.6H6.466v1.293ZM10.337,6.9l5.6-5.6H11.68V0h6.466V6.465H16.854V2.205l-5.6,5.6ZM1.293,2.2v4.26H0V0H6.466V1.293H2.209l5.6,5.6L6.9,7.809Z' transform='translate(1.293 1.293)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
`;

export const Edit = styled.div`
  position: absolute;
  bottom: 9px;
  right: 40px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 29px;
  height: 23px;
  background-image: url("data:image/svg+xml,%3Csvg id='pencil' xmlns='http://www.w3.org/2000/svg' width='28.653' height='22.93' viewBox='0 0 28.653 22.93'%3E%3Cpath id='_253708480' d='M9.8,23.82l-.7-1.153,6.814-4.28.7,1.153a2.737,2.737,0,0,1-.867,3.734L13.473,24.7A2.648,2.648,0,0,1,9.8,23.82ZM1.8,8.114a.662.662,0,0,0-.919-.22.682.682,0,0,0-.216.933L8.392,21.514,9.529,20.8ZM7.48,4.547a.662.662,0,0,0-.919-.22.684.684,0,0,0-.216.933l7.726,12.687,1.136-.713Zm5.456,14.112L5.209,5.973a1.324,1.324,0,0,0-1.838-.44A1.365,1.365,0,0,0,2.939,7.4l7.725,12.685ZM.184,6.739a1.924,1.924,0,0,1,1.234-.258,2.814,2.814,0,0,1,.055-.417,2.692,2.692,0,0,1,1.2-1.683,2.62,2.62,0,0,1,2.018-.333,2.628,2.628,0,0,1,.364.127,2,2,0,0,1,.808-1c.036-.023.078-.024.116-.043L0,0,.093,6.81c.032-.022.058-.05.091-.071Z' transform='matrix(-0.259, -0.966, 0.966, -0.259, 4.403, 22.93)' fill='%23fff' fill-rule='evenodd'/%3E%3C/svg%3E ");
`;

export const Delete = styled.div`
  position: absolute;
  bottom: 9px;
  right: 11px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 19px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg id='noun_bin_2034046' xmlns='http://www.w3.org/2000/svg' width='18.359' height='23.317' viewBox='0 0 18.359 23.317'%3E%3Cg id='Group_24384' data-name='Group 24384'%3E%3Cpath id='Path_30935' data-name='Path 30935' d='M30.027,4.218H25.118V4.169A1.655,1.655,0,0,0,23.449,2.5H20.111a1.655,1.655,0,0,0-1.669,1.669v.049H13.533a.932.932,0,0,0-.933.933v.933a.932.932,0,0,0,.933.933H30.027a.932.932,0,0,0,.933-.933V5.151A.932.932,0,0,0,30.027,4.218Z' transform='translate(-12.6 -2.5)' fill='%23fff'/%3E%3Cpath id='Path_30936' data-name='Path 30936' d='M34.075,26.1H20.035a.439.439,0,0,0-.442.491L20.281,40.8a2.963,2.963,0,0,0,2.945,2.823H30.86A2.946,2.946,0,0,0,33.8,40.8l.687-14.211A.433.433,0,0,0,34.075,26.1ZM25.533,39.82a.785.785,0,1,1-1.571,0V29.929a.785.785,0,0,1,1.571,0Zm4.614,0a.785.785,0,1,1-1.571,0V29.929a.785.785,0,0,1,1.571,0Z' transform='translate(-17.876 -20.307)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E ");
`;

export const Add = styled.div`
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='49.369' height='49.369' viewBox='0 0 49.369 49.369'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Crect width='21.904' height='21.904' fill='none'/%3E%3C/clipPath%3E%3C/defs%3E%3Ccircle cx='24.685' cy='24.685' r='24.685' fill='%23707070'/%3E%3Cg transform='translate(24.685 9.195) rotate(45)' clip-path='url(%23a)'%3E%3Cpath d='M.288,21.626a.844.844,0,0,0,.63.277.86.86,0,0,0,.691-.277l9.351-9.355,9.32,9.355a.86.86,0,0,0,.645.277.844.844,0,0,0,.645-.277.876.876,0,0,0,0-1.275L12.219,10.98,21.57,1.656A.906.906,0,1,0,20.3.381L10.929,9.736,1.609.334A.906.906,0,1,0,.334,1.61l9.351,9.355L.334,20.336a.876.876,0,0,0,0,1.275' transform='translate(0)' fill='%23fff' stroke='%23fff' stroke-miterlimit='10' stroke-width='0.9'/%3E%3C/g%3E%3C/svg%3E");
`;

export const Container = styled.div`
  max-width: 280px;
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

export const Preview = styled.div<PreviewProps>`
  position: relative;
  width: 245px;
  height: 180px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  background: ${(props) => props.background};

  ${mediaQueries.desktop} {
    width: 318px;
  }
`;
