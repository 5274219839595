import { FC } from 'react';
import {
  StyleWrapper,
  Container,
  Text,
  FlexRow,
  Flag,
  FlagImg,
  Country,
  Line,
} from './styles';

import MyAccount from './MyAccount';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import useNavigate from '../../utils/hooks/useNavigate';

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

const Header: FC = () => {
  const {
    data: { isLoggedIn },
  } = useQuery(IS_LOGGED_IN);
  const navigate = useNavigate();

  useEffect(() => {
    // send the user to the login page / home page when login status changes
    if (isLoggedIn === false) navigate.login();
    // else navigate.home();
  }, [isLoggedIn]);

  return (
    <StyleWrapper>
      <Container>
        <FlexRow>
          <Text color='#999999' fontFamily='BrownProTT'>
            Sales
          </Text>

          <Text color='#999999' fontFamily='BrownProTTLight'>
            View
          </Text>
        </FlexRow>

        <FlexRow width='20.875rem'>
          <Flag imageUrl={FlagImg} />

          <Country whileHover={{ color: '#fff' }}>EN</Country>

          <Line />

          <MyAccount />
        </FlexRow>
      </Container>
    </StyleWrapper>
  );
};

export default Header;
