/**
 *
 * SendProposalContainer
 *
 */
import React from 'react';
import {FormattedMessage} from 'react-intl';
import messages from './messages';
import {ContentArea} from "../../layout/ContentArea";
import styled from "styled-components";
import Slider from '@mui/material/Slider';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import SendProposalForm from "../../components/Forms/SendProposalForm";

interface SendProposalContainerProps {
}

const SendProposalContainer = ({}: SendProposalContainerProps) => {

    return (
        <Wrapper>
            <ContentArea>
                <Heading>
                    <FormattedMessage {...messages.sendTailoredSolution} />
                </Heading>
                <SendProposalForm/>
            </ContentArea>
        </Wrapper>
    );
}


const Wrapper = styled.div`
  width: 100%;
  background-color: #F7F7F7;
`;

const Heading = styled("h1")`
  font-size: 61px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 20px
`;

export default SendProposalContainer;
