/**
 *
 * SubHeader
 *
 */
// @ts-nocheck
import { FC, Fragment, useState, useEffect } from 'react';
import SubHeaderIpad from '../SubHeaderIpad';
import SubHeaderDesktop from '../SubHeaderDesktop';
import { schema } from '../../routes';
import { useLocation, matchPath } from 'react-router-dom';

const SubHeader: FC = () => {
  const [currentRouteSchema, setCurrentRouteSchema] = useState([]);

  const location = useLocation();

  const getCurrentRouteSchema = () =>
    schema().filter((route) =>
      matchPath(location.pathname, { path: route.path, exact: true })
    );

  useEffect(() => {
    setCurrentRouteSchema(getCurrentRouteSchema());
    // eslint-disable-next-line
  }, [location]);

  return (
    <Fragment>
      <SubHeaderIpad currentRouteSchema={currentRouteSchema} />
      <SubHeaderDesktop currentRouteSchema={currentRouteSchema} />
    </Fragment>
  );
};

export default SubHeader;
