/**
 *
 * ButtonHover
 *
 */

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ButtonProps } from './types';

export const Button = styled(motion.button)<ButtonProps>`
  all: unset;
  box-sizing: border-box;
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.marginLeft};
  width: ${(props) => props.width || '311px'};
  height: 64px;
  background: #2e3132;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'BrownProTTBold';
  text-transform: uppercase;
  border: 2px solid #2e3132;
`;
