import { useState } from 'react';

import {
  GridItemWrapper,
  GridImage,
  Title,
  Drag,
  Edit,
  Delete,
} from './styles';

import EditModal from '../BrickModal/EditModal';

// @ts-ignore
const GridItem = ({ forwardedRef, style, colourCode, name, link }: GridItemProps) => {
  const [modals, setModals] = useState<ModalI>({
    editModal: false,
    deleteModal: false,
  });

  const { editModal, deleteModal } = modals;

  return (
    <GridItemWrapper ref={forwardedRef} style={style}>
      <GridImage
        animate={{ background: colourCode }}
        transition={{ duration: 1 }}
        initial={false}
      >
        {name && <Title onClick={() => !!link && window.open(link, "_blank")} style={{cursor: 'pointer'}}>{name}</Title>}
        <Drag />
        <Edit onClick={() => setModals({ ...modals, editModal: !editModal })} />
        <Delete />
        <EditModal isOpen={editModal} modals={modals} setModals={setModals} />
      </GridImage>
    </GridItemWrapper>
  );
};

export default GridItem;
