/**
 * * Application Theme
 *
 * This file should contain all the
 * configurations of the Material UI theme,
 * colors, media queries and style constants.
 */

import { createTheme } from '@material-ui/core/styles';

const muiSwitchSize: number = 30;

export const colors: Record<string, string> = {
  Dark: '#2E3132',
  DarkLighter: '#181818',
  Error: '#f40102',
  GrayDark: '#1F1F1F',
  GrayLight: '#363636',
  GrayMedium: '#707070',
  Light: '#ffffff',
  LightDarker: '#676767',
  Primary: '#2E3132',
  SignBlue: '#1B55B2',
  MattGray: '#3C3C3C',
  MattWhite: '#B2B2B2',
};

// export const mediaQueries: MediaQueries = {
//     mobile: "@media (max-width: 425px)",
//     tablet: "@media (max-width: 1024px)",
//     laptop: "@media (max-width: 1440px)",
//     desktop: "@media (min-width: 1441px)",
// };

export const layout: Record<string, any> = {
  // Heights are calculated in "vh" to a maxium of 100.
  headerHeight: 5,
  contentHeight: 87,
  footerHeight: 8,
};

export const constants: Constants = {};

export const appTheme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: colors.MattGray,
      paper: colors.GrayDark,
    },
    // primary: {
    //     main: colors.Primary,
    //     contrastText: colors.Light,
    // },
    secondary: {
      main: colors.Light,
      contrastText: colors.GrayDark,
    },
    error: {
      main: colors.Error,
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  typography: {
    h1: {
      fontSize: '1.375rem',
      fontWeight: 'bold',
      color: colors.Light,
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 'normal',
      letterSpacing: '-1px',
    },
    h6: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '0.8rem',
      color: colors.Light,
    },
    h5: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '0.7rem',
      color: colors.Light,
    },
    body1: {
      fontSize: '0.7rem',
      color: colors.Dark,
    },
    subtitle2: {
      fontWeight: 'bold',
      fontSize: '0.9rem',
    },
    caption: {
      color: colors.Light,
      letterSpacing: '0.5px',
    },
    button: {
      fontWeight: 'bold',
      letterSpacing: '1px',
    },
    fontFamily: ['BrownProTT'].join(','),
  },
  overrides: {
    MuiCircularProgress: {
      root: {
        margin: 'auto',
        colourPrimary: 'red',
        color: 'red',
      },
    },
    MuiButton: {
      root: {
        paddingLeft: '20px',
        paddingRight: '20px',
        fontSize: '20px',
        borderRadius: '0px',
        backgroundColor: '#2E3132',
        border: `1px solid #707070`,

        fontFamily: ['BrownProTT'].join(','),
        fontWeight: 'bold',
        minWidth: '241px',
        minHeight: '64px',

        '&:hover': {
          backgroundColor: '#FFFFFF',
          color: '#2E3132',
        },
      },
      contained: {
        backgroundColor: '#FFFFFF',
        boxShadow: 'none',

        '&:hover': {
          backgroundColor: '#2E3132',
          color: '#FFFFFF',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderColor: 'green',
      },

      input: {
        // backgroundColor: colors.Light,
        borderRadius: '5px',
        color: colors.GrayMedium,
        padding: '0.5rem',
        fontFamily: ['BrownProTT'].join(','),
        fontSize: '0.8rem',
        fontWeight: 'bold',
      },
    },
    MuiSelect: {
      root: {
        padding: '5px',
      },
    },
    MuiMenu: {
      list: {
        width: '147px',
        borderRadius: '0px',
      },
      paper: {
        borderRadius: '0px',
        // top: "63px",
      },
    },
    MuiSwitch: {
      // colorPrimary: {
      //   main: colors.Primary,
      // },
      // colorSecondary: {
      //   main: colors.GrayLight,
      // },
      root: {
        width: muiSwitchSize * 2,
        height: muiSwitchSize,
        padding: 0,
        display: 'flex',
      },
      switchBase: {
        padding: 2,
        color: colors.Light,
        '&$checked': {
          transform: `translateX(${muiSwitchSize * 2 - muiSwitchSize}px)`,
          color: colors.Light,
          '& + $track': {
            backgroundColor: `${colors.Primary} !important`,
            opacity: 1,
            border: 'none',
          },
        },
      },
      thumb: {
        width: muiSwitchSize - 4,
        height: muiSwitchSize - 4,
        boxShadow: 'none',
      },
      track: {
        borderRadius: muiSwitchSize / 2,
        opacity: 1,
        backgroundColor: colors.LightDarker,
      },
      checked: {},
    },
    MuiAccordion: {
      root: {
        '&:before': {
          height: '0px',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        borderStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: `${colors.Primary} !important`,
        backgroundColor: colors.MattGray,
        marginTop: '15px',
        borderTop: colors.MattGray,
      },
    },
    MuiAccordionDetails: {
      root: {
        backgroundColor: colors.MattGray,
        padding: '0px',
      },
    },
    MuiTable: {
      root: {},
    },
    MuiTableRow: {
      head: {},
    },
    MuiTableCell: {
      root: {},
    },
    MuiSvgIcon: {
      root: {
        color: colors.GrayMedium,
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '62px',
      },
    },
  },
  props: {},
});

interface Colors {
  readonly Primary: string;
  readonly Secondary: string;
  readonly TextPrimary: string;
  readonly TextSecondary: string;
  readonly BackgroundLight: string;
  readonly BackgroundDark: string;
  readonly Error: string;
}

interface Constants {}

interface MediaQueries {
  readonly mobile: string;
  readonly tablet: string;
  readonly laptop: string;
  readonly desktop: string;
}

export default appTheme;
