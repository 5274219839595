/**
*
* FormikHandler
*
*/

import React from 'react'
import {Grid, Typography, InputLabel, MenuItem, InputAdornment, SvgIcon} from '@material-ui/core'
import { Form, Formik, FormikProps, Field, FormikValues } from "formik";
import TextField from '../FormFields/TextField'
import SelectField from '../FormFields/SelectField';
import Button from '../Button'
import FileDrop from '../FileDrop';
import Asynchronous from '../FormFields/AsyncSelectField';

const CreateOrganisationForm = ({handleSubmit, initialValues, name, placeholder, children}: any) => {
    return (
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    // @ts-ignore
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        isValid,
                        errors,
                        touched,
                        setFieldValue
                    }: FormikProps<any>) => (
                      <>
                        {/*{children || <Asynchronous placeholder={placeholder} name={name} />}*/}
                      </>
                    )}
                </Formik>
    )
}

export default CreateOrganisationForm
