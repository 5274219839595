/**
 *
 * MeetingSpaces
 *
 */

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { IconProps } from './types';
import { mediaQueries } from '../../utils/styledTheme';

export const StyleWrapper = styled(motion.div)`
  margin-top: 55px;
  position: relative;
  z-index: 0;
  width: 100%;
  height: auto;
  padding-bottom: 111px;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;

  ${mediaQueries.desktop} {
    overflow-x: visible;
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 740px;
  display: flex;
  justify-content: space-between;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
  }

  ${mediaQueries.desktop} {
    width: 1314px;
  }
`;

export const Header = styled.div`
  font-size: 30px;
  font-family: 'BrownProTTLight';
  height: auto;
  display: flex;
  align-items: center;
  width: 740px;
  margin: 0 auto;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
  }

  ${mediaQueries.desktop} {
    width: 1314px;
  }
`;

export const MeetingSpaceWrapper = styled.div`
  margin-top: 55px;
  width: 12%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  width: fit-content;
  height: 20px;
  color: #000000;
  font-family: 'BrownProTTBold';
  text-transform: capitalize;
`;

export const Icon = styled.img<IconProps>`
  margin-top: 11px;
  cursor: pointer;
  width: 100%;
  height: 113px;
  background-size: cover;
  background-position: center;
  background-image: ${({ imageUrl }) => `url("${imageUrl}")`};
  background-repeat: no-repeat;
  border-radius: 10px;
  border: 3px solid ${({ selected }) => (selected ? '#00EACF' : 'transparent')};

  &:hover {
    border: 3px solid lightGrey;
  }
`;
