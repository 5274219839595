import SmallRoom from '../../images/rooms/SmallRoom.png'
import MediumRoom from '../../images/rooms/MediumRoom.png'
import Rallybar from '../../images/hardware/RallyBarMini.png'
import RallybarWallmount from '../../images/hardware/RallyBarMiniWallMount.png'
import Tap from '../../images/hardware/Tap.png'
import Swytch from '../../images/hardware/Swytch.png'
import Tablemic from '../../images/hardware/TableMic.png'
import TablemicMount from '../../images/hardware/TableMicMount.png'
import TopRiser from '../../images/hardware/TopRiserTableMount.png'
import {ReactComponent as ProductLiterature} from '../../images/hardware/ProductLiterature.svg'
import {ReactComponent as Datasheet} from '../../images/hardware/DataSheet.svg'
import {ReactComponent as ProductVideos} from '../../images/hardware/ProductVideos.svg'
import {ReactComponent as WiringDiagram} from '../../images/hardware/WiringDiagrams.svg'

const info = [
    {name: "Product Literature", icon: ProductLiterature, url: "#"},
    {name: "Data Sheet", icon: Datasheet, url: "#"},
    {name: "ProductVideos", icon: ProductVideos, url: "#"},
    {name: "Wiring Diagram", icon: WiringDiagram, url: "#"},
]

const rallybar = {
    name: "Rally Bar Mini",
    selected: false,
    imageUrl: Rallybar,
    info
}

const tap = {
    name: "Tap",
    selected: false,
    imageUrl: Tap,
    info
}

const swytch = {
    name: "Swytch",
    selected: false,
    imageUrl: Swytch,
    info
}

const tableMic = {
    name: "Table Mic",
    selected: false,
    imageUrl: Tablemic,
    info
}

const rallybarMount = {
    name: "Rally Bar Mini Wall Mount",
    selected: false,
    imageUrl: RallybarWallmount,
    info
}

const tableMicMount = {
    name: "Table Mic Mount",
    selected: false,
    imageUrl: TablemicMount,
    info
}

const topRiser = {
    name: "Tap Riser Table Mount",
    selected: false,
    imageUrl: TopRiser,
    info
}


export default [
    {
        name: "desktop",
        imageUrl: SmallRoom,
        selected: false,
        videoSolutions: [rallybar, tap],
        byo: [swytch],
        audio: [tableMic],
        accessories: [rallybarMount, tableMicMount]
    },
    {
        name: "small",
        imageUrl: SmallRoom,
        selected: false,
        videoSolutions: [rallybar],
        byo: [swytch],
        audio: [],
        accessories: [rallybarMount, topRiser]
    },
    {
        name: "medium",
        imageUrl: MediumRoom,
        selected: false,
        videoSolutions: [tap],
        byo: [],
        audio: [],
        accessories: [rallybarMount]
    }
]