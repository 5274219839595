/**
 *
 * TableProof
 *
 */

import styled from "styled-components";
import {TableCell, Theme, withStyles, createStyles, makeStyles} from '@material-ui/core'

export const StyleWrapper = styled.div`
  display: flex;
`;

export const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        }
    }),
)(TableCell);

export const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});


