/**
*
* TextField
*
*/

import React from 'react';
import { Props } from "./types";
import { Input } from './styles'
import {TextFieldProps} from '@material-ui/core/TextField'

const TextField: React.FC<Props & TextFieldProps> = (props) => {
  return (
    <Input {...props} />
  );
}

export default TextField;