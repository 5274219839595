/**
*
* Carousel
*
*/

import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyleWrapper = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const SlideWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  flex-direction: column;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-bottom: 3rem;
`
export const SlideBackground = styled.img`
  object-fit: cover;
  cursor: pointer;
`
export const Title = styled.h4`
  text-align: center;
  font-size: 31px;
  color: #000000;
  font-family: "BrownProTTBold" !important;
  text-transform: uppercase;
  font-weight: bolder;
  margin-bottom: 0;
`
export const StyledButton = styled.div<{isSelected?: boolean}>`
  background-color: ${({isSelected}) => isSelected ? "#00B8FC" : "transparent"};
  color: #000000;
  width: 240px;
  white-space: nowrap;
  text-align: center;
  padding: 10px;
  border: none;
  font-family: "BrownProTTBold" !important;
  font-size: 20px;
  text-transform: uppercase;

  &:hover {
    background-color: ${({isSelected}) => isSelected ? "#00B8FC" : "transparent"};
    color: #000000;
  }
`