/**
 *
 * InlineTitleField
 *
 */

import styled from 'styled-components';
import {Field} from 'formik'
import {Props} from './types'

export const grey = '#E1E2E3';
export const fontColor = '#707070';
export const blue = '#265FC5';
export const lightBlue = '#4fb7f6';
export const black = '#000000';

export const StyleWrapper = styled.div`
  display: flex;
`;

export const Input = styled(Field)<Props>`
  all: unset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: ${({width}) => !!width ? width : 'fit-content'};
  height: 72px;
  margin-top: ${(props) => props.marginTop};
  border-radius: 5px;
  border: 2px solid ${grey};
  padding-left: 1rem;
  padding-right: 1rem;
  color: ${fontColor};
  
  max-width: 95vw;
  min-width: 75px;
  
  
  font-size: 61px;
  font-weight: bold;
  text-transform: uppercase;

  &:focus {
    border: 3px solid ${blue};
  }

  &:disabled {
    border: none;
    color: ${black};
    padding: 0px;
  }
`;

