/**
 *
 * SubHeaderIpad
 *
 */

import { FC, Fragment, useContext } from 'react';
import { Props } from './types';
import { StyleWrapper, Container, SubHeaderContainer } from './styles';

import Logo from '../Logo';
import { MenuToggle } from './MenuToggle';
import Menu from './Menu';

import SubMenuContext from '../../context/submenu/subMenuContext';
import { SubMenuI } from '../../context/submenu/types';

const SubHeaderIpad: FC<Props> = ({ currentRouteSchema }) => {
  const subMenuContext = useContext(SubMenuContext) as SubMenuI;
  const { menuState } = subMenuContext;

  return (
    <Fragment>
      <StyleWrapper borderBottom={`${menuState ? 0 : 1}px solid #707070`}>
        <Container>
          <Logo width='187px' height='56px' />

          {/* no sub header component use links instead */}
          {!currentRouteSchema[0]?.subheaderComponent && <MenuToggle />}
        </Container>

        {/* has sub header component */}
        {currentRouteSchema[0]?.subheaderComponent && (
          <SubHeaderContainer>
            {currentRouteSchema[0]?.subheaderComponent()}
          </SubHeaderContainer>
        )}
      </StyleWrapper>

      <Menu />
    </Fragment>
  );
};

export default SubHeaderIpad;
