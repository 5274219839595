// @ts-nocheck
import { FC, useContext, Fragment } from 'react';
import { MenuWrapper } from './styles';
import MenuItem from './MenuItem';
import SubItems from './SubItems';

import { schema } from '../../routes';

import { useLocation } from 'react-router-dom';
import { data } from '../SubHeaderDesktop/mockData';

import SubMenuContext from '../../context/submenu/subMenuContext';
import { SubMenuI } from '../../context/submenu/types';
import { useHistory } from 'react-router-dom';

const Menu: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const returnAuthorisedRoutes = (schema: any) => {
    return schema.filter(
      (route: any) =>
        route?.nav?.moduleKey &&
        data?.permissions?.[route?.nav?.moduleKey]?.hasModule
    );
  };

  const subMenuContext = useContext(SubMenuContext) as SubMenuI;
  const { menuState, setMenuState } = subMenuContext;

  function* generator(path: string) {
    yield history.push(path);
    yield setMenuState(false);
  }

  return (
    <Fragment>
      {menuState && (
        <MenuWrapper
          onClose={() => setMenuState(false)}
          animate={{ opacity: menuState ? 1 : 0 }}
          initial={false}
          transition={{ duration: 0.5 }}
        >
          {returnAuthorisedRoutes(schema())?.map((route) =>
            route?.nav?.subItems ? (
              <SubItems
                route={route}
                location={location}
                generator={generator}
              />
            ) : (
              <MenuItem
                route={route}
                location={location}
                generator={generator}
              />
            )
          )}
        </MenuWrapper>
      )}
    </Fragment>
  );
};

export default Menu;
