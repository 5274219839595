/**
 *
 * EditModal
 *
 */

import React, { useEffect, useContext } from 'react';
import { EditModalProps, InputProps } from './types';

import { useFormik } from 'formik';
import Modal from 'react-modal';
import ButtonHover from '../ButtonHover';

import {
  Container,
  Title,
  Form,
  TextInput,
  TextArea,
  InputWrapper,
  Label,
  Flex,
} from './styles';

import FileInputHelper from './FileInputHelper';

Modal.setAppElement('#root');

const customStyles = {
  content: {
    maxWidth: '1340px',
    margin: 'auto',
    height: '695px',
    width: '70%',
    borderRadius: '6px',
    background: '#f7f7f7',
    padding: 0,
    minHeight: '695px',
    onset: 0,
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.84)',
    padding: 0,
    overflow: 'visible',
    onset: 0,
    zIndex: 999999999999,
  },
};

const InputHelper = ({ label, ...props }: InputProps) => (
  <InputWrapper {...props}>
    <Label>{label}</Label>
    {props.type === 'textarea' ? (
      <TextArea />
    ) : (
      <TextInput padding={props.padding} />
    )}
  </InputWrapper>
);

const EditModal: React.FC<EditModalProps> = ({ isOpen, modals, setModals }) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      url: '',
      file: '',
      freeText: '',
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  useEffect(() => {
    console.log(formik.values);
  }, [formik.values]);

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={() => setModals({ ...modals, editModal: false })}
    >
      <Container>
        <Title>edit proposal brick</Title>

        <Form onSubmit={formik.handleSubmit}>
          <InputHelper
            label='Name'
            id='name'
            name='name'
            type='text'
            onChange={formik.handleChange}
            value={formik.values.name}
            padding='0 2.5%'
          />

          <Flex>
            <InputHelper
              label='URL'
              id='url'
              name='url'
              type='text'
              onChange={formik.handleChange}
              value={formik.values.url}
              width='49%'
              padding='0 5%'
            />

            <FileInputHelper
              label='File'
              setFieldValue={formik.setFieldValue}
            />
          </Flex>

          <InputHelper
            label='Free Text'
            id='freeText'
            name='freeText'
            type='textarea'
            onChange={formik.handleChange}
            value={formik.values.freeText}
            minHeight='129px'
            padding='0 2.5%'
          />

          <Flex marginTop='51px' justify='flex-end'>
            <ButtonHover
              label='CANCEL'
              width='193px'
              backgroundStart='#fff'
              backgroundHover='#393B3A'
              colorStart='#393B3A'
              colorHover='#fff'
              onClick={() => setModals({ ...modals, editModal: false })}
            />
            <ButtonHover
              label='SAVE'
              width='193px'
              backgroundStart='#393B3A'
              backgroundHover='#fff'
              colorStart='#fff'
              colorHover='#393B3A'
              marginLeft='15px'
            />
          </Flex>
        </Form>
      </Container>
    </Modal>
  );
};

export default EditModal;
