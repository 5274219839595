/**
 *
 * * CreateUser Messages
 *
 */

import { defineMessages } from "react-intl";

export const scope = "Components.CreateUser";

export default defineMessages({
  exampleMessage: {
    id: `${scope}.exampleMessage`,
    defaultMessage: "AddInstance",
  },
  newName: {
    id: `${scope}.newName`,
    defaultMessage: "Name",
  },
  fqdn: {
    id: `${scope}.fqdn`,
    defaultMessage: "FQDN",
  },
  platform: {
    id: `${scope}.platform`,
    defaultMessage: "Platform",
  },
  selectPlatformPlaceholder: {
    id: `${scope}.selectPlatformPlaceholder`,
    defaultMessage: "SELECT PLATFORM",
  },
  gcpProject: {
    id: `${scope}.gcpProject`,
    defaultMessage: "GCP Project",
  },
  selectGcpProjPlaceholder: {
    id: `${scope}.selectGcpProjPlaceholder`,
    defaultMessage: "SELECT GCP PROJECT",
  },
  zone: {
    id: `${scope}.zone`,
    defaultMessage: "Zone",
  },
  instanceId: {
    id: `${scope}.instanceId`,
    defaultMessage: "Instance ID",
  },
  submitLabel: {
    id: `${scope}.submitLabel`,
    defaultMessage: "add instance",
  },
});
