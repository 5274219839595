/**
 *
 * YourRoomSolutions
 *
 */

import React, { useContext, useEffect } from 'react';
import { YourRoomSolutionsContainer, Title, EditIcon } from './styles';

import { useIntl } from 'react-intl';

import { useAnimation } from 'framer-motion';
import ProposalContext from '../../containers/ViewProposalContainer/propsalContext';
import { ProposalI } from '../../containers/ViewProposalContainer/types';
import imageUrl from '../../images/rooms/YourRoomSolutions.jpg';
import messages from './messages';

const YourRoomSolutions: React.FC = () => {
  const proposalContext = useContext(ProposalContext) as ProposalI;
  const { meetingSpaces, setMeetingSpaces, brickSelector } = proposalContext;
  const intl = useIntl();

  const roomControls = useAnimation();
  const editControls = useAnimation();

  const open = async () => {
    await roomControls.start({ opacity: 0.5 });
    return await editControls.start({ opacity: 0 });
  };

  const close = async () => {
    await roomControls.start({ opacity: 1 });
    return await editControls.start({ opacity: 1 });
  };

  useEffect(() => {
    if (meetingSpaces) {
      open();
    } else {
      close();
    }
    // eslint-disable-next-line
  }, [meetingSpaces]);

  const onClick = () => {
    setMeetingSpaces(!meetingSpaces);
  };

  if (brickSelector) {
    return null;
  }

  return (
    <YourRoomSolutionsContainer
      animate={roomControls}
      imageUrl={imageUrl}
      onClick={() => onClick()}
    >
      <EditIcon animate={editControls} />
      <Title>{intl.formatMessage(messages.yourRoomSolutions)}</Title>
    </YourRoomSolutionsContainer>
  );
};

export default YourRoomSolutions;
