/*
 * ViewProposalContainer Messages
 *
 * This contains all the text for the ViewProposalContainer component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.ViewProposalContainer.header',
    defaultMessage: 'This is ViewProposalContainer container !',
  },
  proposalReview: {
    id: 'app.containers.ViewProposalContainer.proposalReview',
    defaultMessage: 'Proposal Review',
  },
});
