/**
 *
 * Button
 *
 */

import React from "react";
import { Props } from "./types";
import { StyleWrapper } from "./styles";

const Button: React.FC<Props & React.HTMLProps<HTMLButtonElement>> = ({
  children,
  ...props
}) => (
  // @ts-ignore
  <StyleWrapper {...props}>{children}</StyleWrapper>
);

export default Button;
