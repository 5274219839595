/**
 *
 * SendProposalButton
 *
 */

import { FC, useState } from 'react';
import { StyleWrapper, Button } from './styles';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

import useNavigate from '../../utils/hooks/useNavigate';
import { useParams, useLocation } from 'react-router';

const SendProposalButton: FC = () => {
  const [isHovered, setHovered] = useState<boolean>(false);

  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const buttonClicked = () => {
    navigate.proposalsSend({ params: { id } });
  };
  return (
    <StyleWrapper>
      <Button
        onClick={buttonClicked}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        border={isHovered ? '#2E3132' : '#00B8FC'}
        animate={{
          background: isHovered ? 'white' : '#00B8FC',
          color: isHovered ? '#2E3132' : 'white',
        }}
      >
        <FormattedMessage {...messages.header} />
      </Button>
    </StyleWrapper>
  );
};

export default SendProposalButton;
