export const data = {
  permissions: {
    roleName: 'Admin',
    contentManagement: {
      hasModule: true,
      canViewSlides: true,
      canAddSlides: true,
      canEditSlides: true,
      canPreviewSlides: true,
      canViewBricks: true,
      canAddBricks: true,
      canEditBricks: true,
      canDeleteBricks: true,
      canReorderBricks: true,
    },
    users: {
      hasModule: true,
      canCreateSuperAdminRole: false,
      canCreateAdminRole: true,
      canCreateSalesRole: true,
      canCreateCustomerRole: true,
      canEditUser: true,
      canDeleteUser: true,
      canResetUserPassword: true,
    },
    clients: {
      hasModule: true,
      canCreateClient: true,
      canEditClient: true,
      canDeleteClient: true,
      canResetPassword: true,
    },
    organisations: {
      hasModule: true,
      canCreateOrganisation: true,
      canEditOrganisation: true,
      canDeleteOrganisation: true,
    },
    proposals: {
      hasModule: true,
      canViewAllProposals: true,
      canCreateProposal: true,
      canReviewProposal: true,
      canShareProposal: true,
      canViewProposal: true,
      canEditProposal: true,
      canDeleteProposal: true,
    },
    clientView: {
      hasModule: false,
      canCreateNewPassword: false,
      canViewYourProposals: false,
      canShareYourProposals: false,
    },
  },
};
