/**
 *
 * StyledModal
 *
 */

import React from 'react';
import {Props} from "./types";
import {StyleWrapper} from './styles'
import Modal from "react-modal";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '0px',
    },
    overlay:  {
        backgroundColor: '#33333387',

    }
};

const StyledModal: React.FC<Props & React.HTMLProps<HTMLButtonElement>> = ({children, isOpen, onRequestClose, ...props}) =>
    <Modal onRequestClose={onRequestClose} isOpen={isOpen} style={customStyles}>{children}</Modal>;


export default StyledModal;
