import React, { useState } from 'react';
import { useStyles } from './styles';
import { Grid, InputLabel } from '@material-ui/core';
import ProposalSchema, { EditProposalInterface } from './formik';
import { Form, Formik, FormikProps } from 'formik';
import messages from './messages';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import {
  GetProposal,
  GetProposal_proposal,
} from '../../../utils/gql/proposals/__generated__/GetProposal';
import InlineTitleField from '../../FormFields/InlineTitleField';
import { ReactComponent as Edit } from '../../../images/svg/EditIcon.svg';
import InlineNameField from '../../FormFields/InlineNameField';
import ProposalBricks from '../../ProposalBricks';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_PROPOSAL } from '../../../utils/gql/proposals/proposalsQuery';
import InlineWysiwygField from '../../FormFields/InlineWysiwygField';
import { data } from './mockData';

interface IProps {
  proposal: GetProposal_proposal | null | undefined;
}

const EditProposalForm = ({ proposal }: IProps) => {
  const classes = useStyles();
  const intl = useIntl();
  const { id } = useParams<any>();
  const [edit, setEdit] = useState(false);

  const handleSubmit = (values: EditProposalInterface) => {
    console.log('boo');
  };

  // @@TODO mock proposal data from initial values
  const initialValues: EditProposalInterface = {
    proposalTitle: proposal?.title,
    customerName: proposal?.customerName,
    proposalIntro: proposal?.intro,
  };

  const calcFieldWidth = (name: string, values: EditProposalInterface) => {
    // @ts-ignore
    if (values[name]?.length === null || !!!values[name]) {
      return '0ch';
    }

    // @ts-ignore
    return `${values[name].length + 2.3}ch`;
  };

  return (
    <Formik
      enableReinitialize
      // initialValues={initialValues}
      initialValues={data}
      // @ts-ignore
      validationSchema={ProposalSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
      }: FormikProps<EditProposalInterface>) => (
        <Form autoComplete='off' className={classes.formStyle}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                <FormattedMessage {...messages.proposalTitle} />
                {':'}
              </InputLabel>
              <InlineTitleField
                name='proposalTitle'
                onBlur={handleBlur}
                variant='outlined'
                onChange={handleChange}
                value={values.proposalTitle}
                marginTop='5px'
                width={calcFieldWidth('proposalTitle', values)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                <FormattedMessage {...messages.companyLogo} />
                {':'}
              </InputLabel>
              {/*{!!proposal?.companyLogo && <WrappedImg src={proposal?.companyLogo}/>}*/}

              {/* @@TODO mocking for company logo, remove this when returning to real data */}
              {<WrappedImg src={data.companyLogo} />}
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                <FormattedMessage {...messages.customerName} />
                {':'}
              </InputLabel>
              <InlineNameField
                name='customerName'
                onBlur={handleBlur}
                variant='outlined'
                onChange={handleChange}
                value={values.customerName}
                marginTop='5px'
                width={calcFieldWidth('customerName', values)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                <FormattedMessage {...messages.proposalIntro} />
                {':'}
                <StyledEdit onClick={() => setEdit(!edit)} />
              </InputLabel>
              <InlineWysiwygField editMode={edit} name={'proposalIntro'} />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const WrappedImg = styled.img`
  max-width: 444px;
`;

const StyledEdit = styled(Edit)`
  position: relative;
  width: 30px;
  height: 30px;
  top: 2px;
  left: 5px;
`;

export default EditProposalForm;
