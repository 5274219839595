/*
 * SendProposalContainer Messages
 *
 * This contains all the text for the SendProposalContainer component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  sendTailoredSolution: {
    id: 'app.containers.SendProposalContainer.sendTailoredSolution',
    defaultMessage: 'Send Tailored Solution',
  },
  confidenceInfo: {
    id: 'app.containers.SendProposalContainer.confidenceInfo',
    defaultMessage: 'Are you confident that you have all the information you need?',
  },
  confidenceBuying: {
    id: 'app.containers.SendProposalContainer.confidenceBuying',
    defaultMessage: 'Are you confident the right information for the rest of your buying group is included?',
  },
});
