import {
    createStyles,
    makeStyles,
    Theme,
  } from "@material-ui/core/styles";
  
  export const fontColor = '#393b3a';
  export const grey = '#E1E2E3';
  
  export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            width: "100%"
        },
        formStyle: {
            width: "1193px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            paddingLeft: "1em",
            paddingRight: "1em"
        },
        formTitle: {
            color: fontColor,
            fontSize: 32,
            textTransform: "uppercase",
            fontWeight: "bold"
        },
        inputLabel: {
            color: fontColor,
            fontSize: 18,
            fontWeight: "bold"
        },
        button: {
            width: "100%",
            height: "72px"
        },
        noHoverButton: {
            width: "100%",
            height: "72px",
            color: "#333333",
            backgroundColor: "#f7f7f7"
        }        
    })
  )

  