import styled from "styled-components";

export const ContentArea = styled("div")`
  max-width: 1315px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  
`;
