/**
 *
 * Button
 *
 */

import styled from "styled-components";

export const StyleWrapper = styled.button`
  text-transform: uppercase;
  font-family: "BrownProTT", serif;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #333333;
  padding: 10px 75px;

  &:hover {
    background-color: #f7f7f7;
    color: #333333;
  }
`;
