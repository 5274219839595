/**
 *
 * Background
 *
 */

import React from 'react';
import { Props } from './types';

import { StyleWrapper, Container, Title, Text } from './styles';

import ButtonHover from '../ButtonHover';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const Background = ({ imageUrl, actionButtonClick = () => {} }: Props) => (
  <StyleWrapper imageUrl={imageUrl}>
    <Container marginTop='84px'>
      <Title>
        <FormattedMessage {...messages.welcome} />
        <span>
          <FormattedMessage {...messages.view} />
        </span>
      </Title>
      <Text marginTop='57px'>Customer proposal management like a boss!</Text>
      {/* @@TODO internationalise this*/}
      <ButtonHover
        marginTop='50px'
        onClick={actionButtonClick}
        label='CREATE A PROPOSAL'
        backgroundStart='#2E3132'
        backgroundHover='#fff'
        colorStart='#fff'
        colorHover='#2E3132'
      />
    </Container>
  </StyleWrapper>
);

export default Background;
