/**
*
* InlineWysiwygField
*
*/

import styled from 'styled-components';

export const StyleWrapper = styled.div`
  margin-top: 10px;





  .rdw-editor-wrapper{
    border-radius: 5px;
    border: 2px solid #E1E2E3;
  }


  
  .rdw-editor-toolbar {
    border: none;
  }

  .rdw-editor-main{
    //padding-left: 1rem;
    //padding-right: 1rem;
    padding: 0px 9px 0;
  }
  
  a {
    color: #00B8FC;
    text-decoration: none;
  }
`;
