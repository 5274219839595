/**
 *
 * Footer
 *
 */

import React from 'react';
import { Props } from './types';
import { StyleWrapper, MessageStyles } from './styles';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const Footer = ({}: Props) => (
  <StyleWrapper>
    <MessageStyles>
      <FormattedMessage {...messages.header} />
    </MessageStyles>
  </StyleWrapper>
);

export default Footer;
