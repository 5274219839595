/**
 *
 * SubHeaderDesktop
 *
 */
// @ts-nocheck
import React, { useMemo, useEffect, useState } from 'react';
import { Props } from './types';
import {
  StyleWrapper,
  Container,
  Links,
  StyledLink,
  SubHeaderContainer,
} from './styles';
import Logo from '../Logo';
import { schema, schemaType } from '../../routes';
import { useQuery } from '@apollo/client';
import rolesEnum from '../../RolePermissionsEnum';
import { useLocation, matchPath } from 'react-router-dom';

import { WhoAmI } from '../../utils/gql/login/__generated__/WhoAmI';
import { WHO_AM_I } from '../../utils/gql/login/whoAmIQuery';

// @@TODO mock import to be removed at a later time
import { data } from './mockData';

const SubHeaderDesktop = ({ currentRouteSchema }) => {
  const location = useLocation();

  // @@TODO re-enable here
  // const { loading, error, data } = useQuery<WhoAmI>(WHO_AM_I);

  const returnAuthorisedRoutes = (schema: any) => {
    return schema().filter(
      (route: any) =>
        route?.nav?.moduleKey &&
        data?.permissions?.[route?.nav?.moduleKey]?.hasModule
    );
  };

  return (
    <StyleWrapper>
      <Container>
        <Logo width='187px' height='56px' />

        {/* no sub header component use links instead */}
        {!currentRouteSchema[0]?.subheaderComponent && (
          <Links>
            {returnAuthorisedRoutes(schema)?.map((route) => (
              <StyledLink
                key={route?.nav?.navLabel}
                // to={route?.path}
                to={'/'}
                matchedRoute={!!matchPath(location.pathname, route?.path)}
              >
                {route?.nav?.navLabel}
              </StyledLink>
            ))}
          </Links>
        )}
      </Container>

      {/* has sub header component */}
      {currentRouteSchema[0]?.subheaderComponent && (
        <SubHeaderContainer>
          {currentRouteSchema[0]?.subheaderComponent()}
        </SubHeaderContainer>
      )}
    </StyleWrapper>
  );
};

export default SubHeaderDesktop;
