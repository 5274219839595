import * as Yup from 'yup'

export const createClientSchema = Yup.object().shape({
    organisationName: Yup.string().required('Required field').min(3, 'Must be 3 at least 3 chararacters long'),
    firstName: Yup.string().required('Required field'),
    surname: Yup.string().required('Required field'),
    email: Yup.string().email('Invalid e-mail format').required('Required field'),
})

export interface CreateClientInterface {
    organisationName: string;
    firstName: string;
    surname: string,
    email: string,
    phoneNumber?: string | undefined,
    jobTitle?: string | undefined
}

export const initialValues: CreateClientInterface = {
    organisationName: '',
    firstName: '',
    surname: '',
    email: '',
    phoneNumber: '',
    jobTitle: ''
}