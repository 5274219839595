/**
*
* TooltipContent
*
*/

import React from 'react';
import { Props } from "./types";
import {
  SnapshotTitle,
  SnapshotSubtitle,
  Grid,
  SnapshotCloseButton,
  TooltipLabel,
  TooltipText
} from './styles'

function TooltipContent({title, subtitle, onClose, createdAt, createdBy, lastEdited, lastEditedBy, version, sentTo, dateSent, sharedWith}: Props) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={11}>
        <SnapshotTitle>{title}</SnapshotTitle>
        <SnapshotSubtitle>{subtitle}</SnapshotSubtitle>
      </Grid>
      <Grid item xs={1}>
        <SnapshotCloseButton onClick={onClose}>X</SnapshotCloseButton>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={8}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <TooltipLabel>Date Created:</TooltipLabel>
              <TooltipLabel>Last Edited:</TooltipLabel>
              <TooltipLabel>Version:</TooltipLabel>
            </Grid>
            <Grid item xs={6}>
              <TooltipText>{createdAt}</TooltipText>
              <TooltipText>{lastEdited}</TooltipText>
              <TooltipText>{version}</TooltipText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <TooltipLabel>By: {`${createdBy}`}</TooltipLabel>
          <TooltipLabel>By: {`${lastEditedBy}`}</TooltipLabel>
        </Grid>
      </Grid>
      <Grid xs={12}><hr /></Grid>
      <Grid item xs={4}>
        <TooltipLabel>Sent to:</TooltipLabel>
      </Grid>
      <Grid item xs={8}>
        {sentTo?.map((item: any, index) =>  (
          <TooltipText key={`proposal-sentTo-${index}`}>{`${item.name}, ${item.jobTitle}`}</TooltipText>
        ))}
      </Grid>
      <Grid item xs={4}>
        <TooltipLabel>Date Sent:</TooltipLabel>
      </Grid>
      <Grid item xs={8}>
        <TooltipText>{dateSent}</TooltipText>
      </Grid>
      <Grid item xs={12}><hr /></Grid>
      <Grid item xs={4}>
        <TooltipLabel>Shared with:</TooltipLabel>
      </Grid>
      <Grid item xs={8}>
        {sharedWith?.map((item: any, index) => <TooltipText key={`shared-with-${index}`}>{item.name}</TooltipText>)}
      </Grid>
    </Grid>
  );
}

export default TooltipContent;