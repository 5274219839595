export const constants: Constants = {
  mediaQueries: {
    iPad: 740,
    iPadPro: 1024,
    desktop: 1314,
  },

  fonts: {
    light: 'BrownProTTLight',
    normal: 'BrownProTT',
    bold: 'BrownProTTBold',
  },
};

export const mediaQueries: MediaQueries = {
  iPad: `@media (min-width: ${constants.mediaQueries.iPad}px)`,
  iPadPro: `@media (min-width: ${constants.mediaQueries.iPadPro}px)`,
  desktop: `@media (min-width: ${constants.mediaQueries.desktop}px)`,
};

export const fonts: Fonts = {
  light: `${constants.fonts.light}`,
  normal: `${constants.fonts.normal}`,
  bold: `${constants.fonts.bold}`,
};

interface MediaQueries {
  iPad: string;
  iPadPro: string;
  desktop: string;
}

interface Fonts {
  light: string;
  normal: string;
  bold: string;
}

interface Constants {
  mediaQueries: {
    readonly iPad: number;
    readonly iPadPro: number;
    readonly desktop: number;
  };

  fonts: {
    readonly light: string;
    readonly normal: string;
    readonly bold: string;
  };
}
