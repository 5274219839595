/**
 *
 * SummaryOfActivityMockContainer
 *
 */
import React from 'react';
import {FormattedMessage} from 'react-intl';
import messages from './messages';
import styled from "styled-components";
import mockImage from '../../images/mock/image.png';
import rallyBar from '../../images/mock/RallyBar.jpg';

interface SummaryOfActivityMockContainerProps {
}

const SummaryOfActivityMockContainer = ({}: SummaryOfActivityMockContainerProps) => {

    return (
        <Wrapper>
            {/*<FormattedMessage {...messages.header} />*/}
            <img src={mockImage}/>
            <img src={rallyBar}/>

        </Wrapper>
    );
}

export default SummaryOfActivityMockContainer;

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  img {
      margin-top: 60px;
    margin-bottom: 35px;
  }
`;
