import React, { useEffect } from 'react';
import DragItem from './DragItem';
import AddProposal from './AddProposal';
import { Grid } from './styles';
import GridItem from './GridItem';

import useGridState from './useGridState';

const ProposalGrid: React.FC<ProposalGridProps> = ({ bricks }) => {
  const { gridState, moveItem, initGrid } = useGridState();
  const { items } = gridState;

  useEffect(() => {
    initGrid(bricks);
    // eslint-disable-next-line
  }, [bricks]);


  return (
    <Grid>
      {items?.map(({ id, name, colourCode, link }, index) => (
        <DragItem
          key={id}
          onMoveItem={moveItem}
          id={id}
          name={name}
          colourCode={colourCode}
        >
          {/*// @ts-ignore*/}
          <GridItem name={name} colourCode={colourCode} link={link} />
        </DragItem>
      ))}

      <AddProposal />
    </Grid>
  );
};

export default ProposalGrid;
