import { useState, FC } from 'react';

import SubMenuContext from './subMenuContext';

const SubMenuState: FC = ({ children }) => {
  const [menuState, setMenuState] = useState<boolean>(false);

  return (
    <SubMenuContext.Provider value={{ menuState, setMenuState }}>
      {children}
    </SubMenuContext.Provider>
  );
};

export default SubMenuState;
