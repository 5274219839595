/*
 * TestComp Messages
 *
 * This contains all the text for the TestComp component.
 */

import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    id: "app.components.TestComp.header",
    defaultMessage: "This is the TestComp component !",
  },
});
