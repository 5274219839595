/**
 *
 * SaveOrCancel
 *
 */

import styled from 'styled-components';
import { mediaQueries } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  margin: 25px auto 0;
  width: 740px;
  max-width: 1314px;
  height: auto;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
  }

  ${mediaQueries.desktop} {
    width: 1314px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #707070;
  margin-bottom: 32px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  height: 64px;
`;
