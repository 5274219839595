/**
 *
 * MeetingSpaces
 *
 */

import React, { useContext } from 'react';
import { MeetingSpaceProps } from './types';
import {
  StyleWrapper,
  Container,
  Header,
  MeetingSpaceWrapper,
  Title,
  Icon,
} from './styles';

import ProposalContext from '../../containers/ViewProposalContainer/propsalContext';
import { ProposalI } from '../../containers/ViewProposalContainer/types';

import mockData from './mockData';

const MeetingSpace: React.FC<MeetingSpaceProps> = ({
  name,
  imageUrl,
  onClick,
  selected,
}) => (
  <MeetingSpaceWrapper>
    {name && <Title>{name}</Title>}

    {imageUrl && <Icon onClick={onClick} src={imageUrl} selected={selected} />}
  </MeetingSpaceWrapper>
);

const MeetingSpaces: React.FC = () => {
  const proposalContext = useContext(ProposalContext) as ProposalI;

  const { meetingSpaces, yourRooms, setYourRooms, brickSelector } =
    proposalContext;

  const handleYourRooms = (name: string) => {
    const index = yourRooms.findIndex((room) => room === name);
    if (index === -1) {
      setYourRooms([...yourRooms, name]);
      return;
    }
    const nRooms = [...yourRooms];
    nRooms.splice(index, 1);
    setYourRooms(nRooms);
  };

  if (!meetingSpaces || brickSelector) {
    return null;
  }

  return (
    <StyleWrapper>
      <Header>
        Please select the meeting spaces that are important to you
      </Header>
      <Container>
        {mockData?.map(({ imageUrl, name }) => (
          <MeetingSpace
            onClick={() => handleYourRooms(name)}
            imageUrl={imageUrl}
            name={name}
            selected={yourRooms.includes(name)}
          />
        ))}
      </Container>
    </StyleWrapper>
  );
};

export default MeetingSpaces;
