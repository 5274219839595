/**
 *
 * * CreateProposal Messages
 *
 */

import { defineMessages } from "react-intl";

export const scope = "Components.CreateProposal";

export default defineMessages({
    proposalProjectName: {
        id: `${scope}.proposalProjectName`,
        defaultMessage: "Proposal / Project Name *",
    },
    confidenceInfo: {
        id: `${scope}.confidenceInfo`,
        defaultMessage: 'Are you confident that you have all the information you need?',
    },
    confidenceBuying: {
        id: `${scope}.confidenceBuying`,
        defaultMessage: 'Are you confident the right information for the rest of your buying group is included?',
    },
    send: {
        id: `${scope}.send`,
        defaultMessage: 'Send',
    },
});
