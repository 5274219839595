/**
 *
 * SendProposalButton
 *
 */

import styled from 'styled-components';
import { mediaQueries, fonts } from '../../utils/styledTheme';
import { motion } from 'framer-motion';
import { ButtonProps } from './types';

export const StyleWrapper = styled.div`
  width: 740px;
  height: 0px;
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
  }

  ${mediaQueries.desktop} {
    width: 1314px;
  }
`;

export const Button = styled(motion.button)<ButtonProps>`
  all: unset;
  box-sizing: border-box;
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.marginLeft};
  width: ${(props) => props.width || '311px'};
  height: 64px;
  background: #00b8fc;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: ${fonts.bold};
  text-transform: uppercase;
  border: 2px solid ${(props) => props.border};
`;
