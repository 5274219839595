// @ts-nocheck
import { FC, useState } from 'react';
import { matchPath } from 'react-router-dom';
import {
  SubItemsWrapper,
  Label,
  DropDown,
  DropDownItem,
  Arrow,
} from './styles';

const SubItems: FC<any> = ({ route, location, generator }) => {
  const [showSubItems, setShowSubItems] = useState<boolean>(false);

  return (
    <SubItemsWrapper>
      <Label onClick={() => setShowSubItems(!showSubItems)}>
        {route?.nav?.navLabel}
        <Arrow transform={showSubItems ? 'rotate(0deg)' : 'rotate(180deg)'} />
      </Label>

      {showSubItems && (
        <DropDown>
          {route?.nav?.subItems.map(({ label, path }) => (
            <DropDownItem
              key={label}
              onClick={() => {
                //dont go to path for demo
                // const gen = generator(path);
                const gen = generator('/');
                gen.next();
                gen.next();
              }}
              matchedRoute={!!matchPath(location.pathname, path)}
            >
              {label}
            </DropDownItem>
          ))}
        </DropDown>
      )}
    </SubItemsWrapper>
  );
};

export default SubItems;
