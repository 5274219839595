/**
 *
 * HomeContainer
 *
 */
import React, { Fragment } from 'react';
import imageUrl from '../../images/homeBackground/home.jpg';
import Background from '../../components/Background/index';
import useNavigate from "../../utils/hooks/useNavigate";

interface HomeContainerProps {}

const HomeContainer = ({}: HomeContainerProps) => {
    const navigate = useNavigate();

  return (
    <>
      <Background imageUrl={imageUrl} actionButtonClick={() => navigate.proposalsCreate()}/>
    </>
  );
};

export default HomeContainer;
