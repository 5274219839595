import { gql } from "@apollo/client";

export const GET_PROPOSAL = gql`
    query GetProposal($proposalId: Uuid) {
        proposal(id: $proposalId) {
            id
            title
            customerName
            companyLogo
            mainImageUrl
            intro
            footerImageUrl
            company {
                id
                name
                description
            }
            typeId
            status
            createdDate
            updatedDate
            bricks {
                id
                name
                colourCode
            }
            availableBricks{
                id
                name
                colourCode
            }
        }
    }
`;


