/**
 *
 * Footer
 *
 */

import styled from 'styled-components';

export const StyleWrapper = styled.div`
  flex-shrink: 0;
  z-index: 1;
  position: relative;
  margin-top: auto;
  display: flex;
  align-items: center;
  width: 100%;
  height: 10.2vh;
  background: #252a2c;
`;

export const MessageStyles = styled.div`
  color: white;
  margin-left: 10.4%;
  font-size: 16px;
  font-family: BrownProTT;
  font-weight: 100;
`;
