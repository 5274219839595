import styled from 'styled-components'
import Button from '../../components/Button'
import {Typography, TypographyProps, Theme, withStyles, createStyles, TableRow, TableCell} from '@material-ui/core'

export const Title = styled(Typography)<TypographyProps>`
    text-transform: uppercase;
    color: #000000;
    font-family: "BrownProTT", serif !important;
    font-size: 36px !important;
    font-weight: bolder !important;
`

export const StyledTableRow = withStyles((theme: Theme) =>
     createStyles({
         root: {
             backgroundColor: theme.palette.common.white,
             '&:nth-of-type(even)': {
                 backgroundColor: theme.palette.grey[300]
             },
         }
     }),
 )(TableRow);
 
 export const StyledTableCell = withStyles((theme: Theme) =>
     createStyles({
         root: {
             fontSize: 14,
             color: theme.palette.common.black,
             border: 'none'
         },
     }),
 )(TableCell);

 export const StyledButton = styled(Button)`
     width: 100%
 `