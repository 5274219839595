import React, { useState } from 'react';
import ProposalContext from './propsalContext';
import { SpaceS } from './types';

const ProposalState: React.FC = ({ children }) => {
  const [icons, setIcons] = useState({
    editIcon: false,
    deleteIcon: false,
    dragIcon: false,
  });
  const [brickSelector, setBrickSelector] = useState<boolean>(false);

  const [meetingSpaces, setMeetingSpaces] = useState<boolean>(false);

  const [yourRooms, setYourRooms] = useState<SpaceS>([]);

  const [selectedRoom, setSelectedRoom] = useState(null);

  return (
    <ProposalContext.Provider
      value={{
        icons,
        brickSelector,
        yourRooms,
        meetingSpaces,
        selectedRoom,
        setIcons,
        setBrickSelector,
        setMeetingSpaces,
        setYourRooms,
        setSelectedRoom,
      }}
    >
      {children}
    </ProposalContext.Provider>
  );
};

export default ProposalState;
