import { gql } from "@apollo/client";

export const GET_ALL_PROPOSALS = gql`
  query GetAllProposals {
    proposal {
      id
      title
      company {
        name
        id
      }
    }
  }
`;
