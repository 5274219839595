/**
 *
 * ViewProposalContainer
 *
 */
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { useQuery } from '@apollo/client';
import { GET_PROPOSAL } from '../../utils/gql/proposals/proposalsQuery';
import { GetProposal } from '../../utils/gql/proposals/__generated__/GetProposal';
import { useParams } from 'react-router-dom';

import EditProposalForm from '../../components/Forms/EditProposal';
import ProposalBricks from '../../components/ProposalBricks';
import Carousel from '../../components/Carousel';
import SmallRoom from '../../images/rooms/SmallRoom.png';
import MediumRoom from '../../images/rooms/MediumRoom.png';

import ProposalState from './ProposalState';
import sampleItems from './sampleItems';
import HardwareComponents from '../../components/HardwareComponents';
import SendProposalButton from '../../components/SendProposalButton';
import ProposalContent from '../../components/ProposalContent';

import BrickSelector from '../../components/BrickSelector';
import YourRoomSolutions from '../../components/YourRoomSolutions';
import MeetingSpaces from '../../components/MeetingSpaces';
import SaveOrCancel from '../../components/SaveOrCancel';

import { StyleWrapper, Container, Review } from './styles';

const ViewProposalContainer: FC = () => {
  const { id } = useParams<any>();
  const { loading, error, data } = useQuery<GetProposal>(GET_PROPOSAL, {
    variables: { id },
  });

  const proposal = data?.proposal?.[0];

  const rooms = [
    { name: 'mobile', image: SmallRoom },
    { name: 'desktop', image: MediumRoom },
    { name: 'personal', image: SmallRoom },
    { name: 'small', image: MediumRoom },
  ];

  return (
    <ProposalState>
      <SendProposalButton />
      <StyleWrapper>
        <Container>
          <Review>
            <FormattedMessage {...messages.proposalReview} />
          </Review>
          <EditProposalForm proposal={proposal} />
        </Container>
        <ProposalContent />
        {sampleItems && <ProposalBricks bricks={sampleItems} />}
        <SaveOrCancel />
        <BrickSelector availableBricks={sampleItems} />
        <YourRoomSolutions />
        <MeetingSpaces />

        {/*<pre>*/}
        {/*    {JSON.stringify(proposal, null, 2)}*/}
        {/*</pre>*/}

        <Container>
          <Carousel />
          <HardwareComponents />
        </Container>
      </StyleWrapper>
    </ProposalState>
  );
};

export default ViewProposalContainer;
