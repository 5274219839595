/**
 *
 * ProposalSuccessContainer
 *
 */
import { FC } from 'react';

import {
  StyleWrapper,
  Title,
  Container,
  Success,
  Img,
  imageUrl,
  Th,
} from './styles';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

import ButtonHover from '../../components/ButtonHover';
import useNavigate from '../../utils/hooks/useNavigate';

const ProposalSuccessContainer: FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <StyleWrapper>
      <Container>
        <Title>
          <FormattedMessage {...messages.congratulations} />
        </Title>

        <table>
          <tr>
            <Th>
              <Success>
                {intl.formatMessage(messages.submissionSuccessful)}
              </Success>
              <ButtonHover
                label={intl.formatMessage(messages.createNewProposal)}
                marginLeft='0'
                onClick={() => navigate.proposalsCreate()}
                backgroundStart='#393B3A'
                backgroundHover='#fff'
                colorHover='#393B3A'
              />
              <ButtonHover
                label={intl.formatMessage(messages.viewProposal)}
                marginTop='30px'
                onClick={() => navigate.proposalsView({params: {id: '96212-2323123-09892367-523836'}})}
                marginLeft='0'
                backgroundStart='#393B3A'
                backgroundHover='#fff'
                colorHover='#393B3A'
              />
              <ButtonHover
                  label={"Summary of Activity"}
                  marginTop='30px'
                  onClick={() => navigate.deleteMeMockOnly()}
                  marginLeft='0'
                  backgroundStart='#393B3A'
                  backgroundHover='#fff'
                  colorHover='#393B3A'
              />
            </Th>
            <th>
              <Img imageUrl={imageUrl} />
            </th>
          </tr>
        </table>
      </Container>
    </StyleWrapper>
  );
};

export default ProposalSuccessContainer;
