/**
 *
 * LoginModal
 *
 */
// @ts-nocheck
import React, { Fragment, useState, FC } from 'react';
import { Props } from './types';
import useNavigate from '../../utils/hooks/useNavigate';

import { Formik } from 'formik';

// comps
import Logo from '../Logo';

import Modal from 'react-modal';

// styles
import {
  FormStyled,
  Label,
  Input,
  CheckBoxWrapper,
  CheckBox,
  Text,
  Forgot,
  Submit,
  customStylesLogin,
  customStylesPasswordReset,
  lightBlue,
} from './styles';

import { MyFormValues, LoginProps } from './types';

// graphql
import { useMutation } from '@apollo/client';
import { UserLogin } from '../../utils/gql/login/__generated__/UserLogin';
import { USER_LOGIN } from '../../utils/gql/login/userLoginMutation';
import { isLoggedInVar } from '../../apollo/cache';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

const initialValues: MyFormValues = {
  email: '',
  password: '',
  rememberMe: false,
};

const Login = ({ onClick }: LoginProps) => {
  const [loginUser, { data, loading, error }] =
    useMutation<UserLogin>(USER_LOGIN);
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <Modal isOpen={true} style={customStylesLogin}>
      <Logo marginTop='90px' />
      <Label>
        <FormattedMessage {...messages.loginLabel} />
      </Label>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          localStorage.setItem('token', 'mockToken');
          isLoggedInVar(true);
          navigate.home();

          // @@TODO re-enable this for login to backend
          // loginUser({variables: {...values}})
          //     .then((data) => {
          //
          //         console.log({data})
          //         localStorage.setItem(
          //             'token',
          //             data.data?.userLogin?.loginDetails?.accessToken || ''
          //         );
          //         isLoggedInVar(true);
          //         navigate.home();
          //     });
        }}
      >
        <FormStyled>
          <Input
            id='email'
            type='email'
            name='username'
            placeholder='example@logitech.com'
          />
          <Input
            id='password'
            type='password'
            name='password'
            placeholder='********'
            marginTop='15px'
          />

          <CheckBoxWrapper>
            <CheckBox type='checkbox' name='rememberMe' id='rememberMe' />
            <Text>
              <FormattedMessage {...messages.rememberMe} />
            </Text>
            <Forgot onClick={() => onClick(true)}>Forgot password?</Forgot>
          </CheckBoxWrapper>
          <Submit
            type='submit'
            value={intl.formatMessage(messages.login)}
            background={lightBlue}
            color='black'
          />
        </FormStyled>
      </Formik>
    </Modal>
  );
};

const PasswordReset = () => (
  <Modal isOpen={true} style={customStylesPasswordReset}>
    <Logo marginTop='90px' />
    <Label>
      <FormattedMessage {...messages.passwordLabel} />
    </Label>
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        alert({ values });
      }}
    >
      <FormStyled>
        <Input
          id='email'
          type='email'
          name='email'
          placeholder='example@logitech.com'
        />

        <Submit
          style={{ marginTop: '45px' }}
          type='submit'
          value='SUBMIT'
          background={lightBlue}
          color='black'
        />
      </FormStyled>
    </Formik>
  </Modal>
);

const LoginModal: FC = () => {
  const [forgotMode, setForgotMode] = useState<boolean>(false);

  return (
    <Fragment>
      {forgotMode ? <PasswordReset /> : <Login onClick={setForgotMode} />}
    </Fragment>
  );
};

export default LoginModal;
