/**
 *
 * RouteWrapper
 *
 */

import { useContext, FC, ReactNode, useEffect } from 'react';
import { StyleWrapper } from './styles';

import SubMenuContext from '../../context/submenu/subMenuContext';
import { SubMenuI } from '../../context/submenu/types';
import { useWindowSize, Size } from '../../utils/hooks/useWindowSize';

const RouteWrapper: FC<ReactNode> = ({ children }) => {
  const subMenuContext = useContext(SubMenuContext) as SubMenuI;
  const { menuState, setMenuState } = subMenuContext;

  const size: Size = useWindowSize();

  const { width } = size;

  const resetMenu = (width: number | undefined) => {
    if (!width) return null;
    if (width >= 1314) {
      setMenuState(false);
    }
  };

  useEffect(() => {
    resetMenu(width);
    // eslint-disable-next-line
  }, [width]);

  return (
    <StyleWrapper display={menuState ? 'none' : 'flex'}>
      {children}
    </StyleWrapper>
  );
};

export default RouteWrapper;
