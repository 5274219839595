import {
    createStyles,
    makeStyles,
    Theme, withStyles,
} from "@material-ui/core/styles";
import styled from "styled-components";
import Slider from "@mui/material/Slider";

export const fontColor = '#393b3a';
export const grey = '#E1E2E3';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formStyle: {
            maxWidth: "1193px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            margin: "20px",
        },
    })
)

export const SliderWrapper = styled.div`
  width: 100%;
  max-width: 867px;
  margin: auto;
  margin-bottom: 30px;
  
  // seems to be the only way to override this inside of the form wrapper
  > span > .MuiSlider-thumb {
    height: 32px;
    width: 32px;
  }
`;

export const StyledSlider = withStyles({
    root: {
        color: '#52af77',
        height: 8,
    },
    thumb: {
        backgroundColor: '#fff !important',
        border: '3px solid #00B8FC',
        marginTop: -1,
        // marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 3,
        borderRadius: 0,
        color: '#00B8FC',
    },
    rail: {
        height: 5,
        borderRadius: 0,
        color: '#00B8FC',
        opacity: "1 !important",
    },
    mark: {
        display: 'none',
    },
    markLabel: {
        color: '#707070',
        fontSize: '20px',
        marginTop: '30px',
        fontWeight: 600,
    },
})(Slider);

export const Label = styled.p`
  color: #707070;
  font-size: 32px;
  margin: auto;
  margin-bottom: 30px;
  margin-top: 30px;
  white-space: nowrap;

  @media (max-width: 1300px) {
    white-space: pre-wrap;
  }
`;
