import { GlobalStyle } from './utils/globalStyle';
import Header from './components/header';
import Footer from './components/Footer';
import AppWrapper from './AppWrapper';
import routes from './routes';
import SubHeader from './components/SubHeader';
import RouteWrapper from './components/RouteWrapper';
import Modal from 'react-modal';
Modal.setAppElement('#root');

const App = () => {
  return (
    <AppWrapper>
      <GlobalStyle />
      <Header />
      <SubHeader />
      <RouteWrapper>{routes}</RouteWrapper>
      <Footer />
    </AppWrapper>
  );
};

export default App;
