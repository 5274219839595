/**
 *
 * SaveOrCancel
 *
 */

import { FC, useContext } from 'react';
import { StyleWrapper, Line, BtnWrapper } from './styles';
import ButtonHover from '../ButtonHover';

import ProposalContext from '../../containers/ViewProposalContainer/propsalContext';
import { ProposalI } from '../../containers/ViewProposalContainer/types';

const SaveOrCancel: FC = () => {
  const proposalContext = useContext(ProposalContext) as ProposalI;
  const { icons, setIcons } = proposalContext;
  const { editIcon, deleteIcon } = icons;

  if (!editIcon && !deleteIcon) {
    return null;
  }

  const onCancel = () => {
    setIcons({ ...icons, editIcon: false, deleteIcon: false });
  };

  return (
    <StyleWrapper>
      <Line />
      <BtnWrapper>
        <ButtonHover
          label='SAVE'
          width='193px'
          backgroundStart='#393B3A'
          backgroundHover='#fff'
          colorStart='#fff'
          colorHover='#393B3A'
          marginLeft='15px'
        />

        <ButtonHover
          label='CANCEL'
          width='220px'
          backgroundHover='#393B3A'
          backgroundStart='#fff'
          colorHover='#fff'
          colorStart='#393B3A'
          onClick={onCancel}
        />
      </BtnWrapper>
    </StyleWrapper>
  );
};

export default SaveOrCancel;
