import React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import ProposalBricks from './ProposalGrid';

const DefaultProvider: React.FC<ProposalGridProps> = ({ bricks }) => (
  <DndProvider backend={HTML5Backend}>
    <ProposalBricks bricks={bricks} />
  </DndProvider>
);

export default DefaultProvider;
