/**
*
* Carousel
*
*/

import React, {useContext} from 'react';
import { Props } from "./types";
import { StyleWrapper, Title } from './styles'
import Slides from 'react-multi-carousel'
import {Slide} from './Slide'
import Grid from '@material-ui/core/Grid'
import 'react-multi-carousel/lib/styles.css'
import ProposalContext from '../../containers/ViewProposalContainer/propsalContext';
import mockData from './mockData';

function Carousel({}: Props) {
  const responsive = {
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 2
    }
  }

  const state: any = useContext(ProposalContext)
  const {yourRooms, setYourRooms, setSelectedRoom, selectedRoom} = state

  const filteredData = mockData.filter(room => yourRooms.includes(room.name))

  return (
    <StyleWrapper>
      {yourRooms?.length ? <Title>Your rooms</Title> : null}
      <Slides
        responsive={responsive}
        autoPlay={false}
        infinite
        centerMode={false}
        renderButtonGroupOutside={false}
      >
        {filteredData?.map((item: any, index: number) => (
          <Slide
            isSelected={selectedRoom?.name === item.name}
            onClick={() => setSelectedRoom(item)}
            key={`carousel-slide-${index}`}
            image={item.imageUrl}
            name={item.name}
          />
        ))}
      </Slides>
    </StyleWrapper>
  );
}

export default Carousel;