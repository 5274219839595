/*
 * AuthModal Messages
 *
 * This contains all the text for the AuthModal component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.AuthModal.header',
    defaultMessage: 'This is the AuthModal component !',
  },
  rememberMe: {
    id: 'app.components.AuthModal.rememberMe',
    defaultMessage: 'Remember me on this device',
  },
  loginLabel: {
    id: 'app.components.AuthModal.loginLabel',
    defaultMessage: 'Log in with your Login ID',
  },
  passwordLabel: {
    id: 'app.components.AuthModal.passwordLabel',
    defaultMessage: 'Please enter your email address to reset your password',
  },
  login: {
    id: 'app.components.AuthModal.login',
    defaultMessage: 'Login',
  },
});
