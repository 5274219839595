/**
 *
 * CreateProposalContainer
 *
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import styled from "styled-components";
import {ContentArea} from "../../layout/ContentArea";
import CreateProposalForm from "../../components/Forms/CreateProposal";

interface CreateProposalContainerProps{}

const CreateProposalContainer = ({}: CreateProposalContainerProps) => {

  return (
      <ContentArea>
          <TopWrapper>
              <Heading>
                  <FormattedMessage {...messages.header} />
              </Heading>
          </TopWrapper>
          <CreateProposalForm/>
      </ContentArea>
  );
}


const Heading = styled("h1")`
  font-size: 61px;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 20px
`;

const TopWrapper = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

export default CreateProposalContainer;
