/**
 *
 * Logo
 *
 */

import React from 'react';
import { Props } from './types';
import { StyleWrapper } from './styles';
import logoUrl from './logo.svg';

import useNavigate from '../../utils/hooks/useNavigate';

const Logo = ({ width, height, marginTop }: Props) => {
  const navigate = useNavigate();
  return (
    <StyleWrapper
      width={width}
      height={height}
      marginTop={marginTop}
      onClick={() => navigate.home()}
    >
      <img src={logoUrl} alt='Image description.' />
    </StyleWrapper>
  );
};

export default Logo;
