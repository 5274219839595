/**
 *
 * ButtonHover
 *
 */

import { useState, FC } from 'react';
import { Props } from './types';
import { Button } from './styles';

const ButtonHover: FC<Props> = ({
  marginTop,
  marginLeft,
  width,
  label,
  onClick,
  backgroundStart,
  backgroundHover,
  colorStart,
  colorHover,
}) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <Button
      type='submit'
      marginTop={marginTop}
      marginLeft={marginLeft}
      width={width}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      animate={{
        background: isHovered ? backgroundHover : backgroundStart,
        color: isHovered ? colorHover : colorStart,
      }}
    >
      {label}
    </Button>
  );
};

export default ButtonHover;
