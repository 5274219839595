import React, {useMemo, useState} from 'react';
import {useStyles} from './styles';
import {Grid} from '@material-ui/core';
import ProposalSchema, {
    initialValues,
    CreateProposalInterface,
} from './formik';
import {Form, Formik, FormikProps} from 'formik';
import TextField from '../../FormFields/TextField';
import Button from '@material-ui/core/Button';
import messages from './messages';
import {FormattedMessage, useIntl} from 'react-intl';
import Asynchronous from '../../FormFields/AsyncSelectField';
import styled from 'styled-components';
import {useMutation, useQuery} from '@apollo/client';
import {CreateProposal} from '../../../utils/gql/proposals/__generated__/CreateProposal';
import {CREATE_PROPOSAL} from '../../../utils/gql/proposals/createProposalMutation';
import useNavigate from '../../../utils/hooks/useNavigate';
import CircularProgress from '@material-ui/core/CircularProgress';
import {SEARCH_ORGANISATIONS} from '../../../utils/gql/organisations/searchOrganisationsQuery';
import {SearchOrganisastions} from '../../../utils/gql/organisations/__generated__/SearchOrganisastions';
import MultiSelect from '../../MultiSelect';
import {mockOrganisationData, mockDomains, fetchMockOrgs} from "./mockData"

const CreateProposalForm = () => {
    const classes = useStyles();
    const intl = useIntl();
    const [createProposal, {data, loading, error}] =
        useMutation<CreateProposal>(CREATE_PROPOSAL);
    const navigate = useNavigate();
    const [orgOptions, setOrgOptions] = useState<any>([]);

    const handleSubmit = (values: CreateProposalInterface) => {
        const input = {
            ...values,
            clientEmails: values?.clientEmails?.map((email) => email?.value),
            customerEmail: 'example@example.com',
        };

        // @@TODO re-enable this to make submission happen, mocked for now
        // createProposal({variables: {createProposalInput: input}})
        //     .then((response) => {
        //         // @ts-ignore
        //         navigate.proposalsView({params: {id: response.data?.createProposal?.id}})
        //     })

        // @@TODO mock param return for proposal creation, remove this when taking out mocks
        navigate.proposalsView({params: {id: '96212-2323123-09892367-523836'}});
    };

    const orgKeyStrokeCallback = async (value: string) => {
        await fetchMockOrgs().then(result => setOrgOptions(result))
    }

    // @@TODO - get the current value of the organisation field, and build the options array from it
    // @@TODO - pass this to the multiselect prop for options

    const getDomainOptions = (id: string | undefined) => {
        if (!id) return [];

        const found = mockDomains.find(domain => domain.id === id);

        if (!!found && found?.options?.length > 0)  return found?.options;

        return [];
    }


    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            // @ts-ignore
            validationSchema={ProposalSchema}
            validateOnMount
            onSubmit={handleSubmit}
        >
            {({
                  values,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  isValid,
                  errors,
                  touched,
              }: FormikProps<CreateProposalInterface>) => (
                <Form autoComplete='off' className={classes.formStyle}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                name='name'
                                onBlur={handleBlur}
                                variant='outlined'
                                onChange={handleChange}
                                value={values.name}
                                marginTop='10px'
                                placeholder={intl.formatMessage(messages.proposalProjectName)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StepWrapper>
                <span>
                  <FormattedMessage {...messages.step1} />
                </span>
                                {' - '}
                                <span>
                  <FormattedMessage {...messages.clientDetails} />
                </span>
                            </StepWrapper>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        name='customerFirstName'
                                        onBlur={handleBlur}
                                        variant='outlined'
                                        onChange={handleChange}
                                        value={values.customerFirstName}
                                        marginTop='10px'
                                        placeholder={intl.formatMessage(messages.firstName)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid item xs={12}>
                                <TextField
                                    name='customerLastName'
                                    onBlur={handleBlur}
                                    variant='outlined'
                                    onChange={handleChange}
                                    value={values.customerLastName}
                                    marginTop='10px'
                                    placeholder={intl.formatMessage(messages.lastName)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} wrap={'nowrap'} direction={'row'}>
                            <RowWrapper>
                                <Asynchronous
                                    name={'clientEmails'}
                                    placeholder={intl.formatMessage(messages.pleaseSelectOrganisation)}
                                    searchTermCallback={orgKeyStrokeCallback}
                                    options={orgOptions}
                                    multiple={true}
                                    limit={1}
                                    clearable={false}
                                />
                                <Button>
                                    <FormattedMessage {...messages.createNew} />
                                </Button>
                            </RowWrapper>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        name='customerEmail'
                                        onBlur={handleBlur}
                                        variant='outlined'
                                        onChange={handleChange}
                                        value={values.customerEmail}
                                        marginTop='10px'
                                        placeholder={intl.formatMessage(messages.customerEmail)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid item xs={12}>
                                <MultiSelect
                                    // @ts-ignore
                                    options={getDomainOptions(values?.clientEmails?.[0]?.value)}
                                    addNew={true}
                                    placeholder={intl.formatMessage(messages.emailDomain)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container justifyContent={'center'}>
                            <Button
                                type='submit'
                                disabled={!isValid}
                                style={{marginBottom: '88px', marginTop: '72px'}}
                            >
                                {loading ? (
                                    <CircularProgress size={24}/>
                                ) : (
                                    <FormattedMessage {...messages.nextStep} />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
const StepWrapper = styled.p`
  text-transform: uppercase;
  font-size: 31px;
  font-weight: bold;
  margin: 14px 0px 8px;

  span:first-child {
    color: #00b8fc;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  //width: 100%;
  //
  //:first-child{
  //  flex-grow: 3;
  //}

  > .MuiAutocomplete-root {
    flex-grow: 10;

    .MuiInputBase-root {
      border-right: none;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }

  > button {
    min-width: 386px;
    flex-grow: 4.05;
    margin-top: 10px;
    height: 72px;
  }
`;

export default CreateProposalForm;
