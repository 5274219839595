/**
 *
 * ProposalContent
 *
 */

import { FC, useContext } from 'react';
import { StyleWrapper, FlexRow, Text, Pencil } from './styles';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

import ProposalContext from '../../containers/ViewProposalContainer/propsalContext';
import { ProposalI } from '../../containers/ViewProposalContainer/types';

const ProposalContent: FC = () => {
  const proposalContext = useContext(ProposalContext) as ProposalI;
  const { icons, setIcons } = proposalContext;
  const { editIcon, deleteIcon } = icons;

  return (
    <StyleWrapper>
      <FlexRow height='29px'>
        <Text>
          <FormattedMessage {...messages.header} />
        </Text>
        <Pencil
          onClick={() =>
            setIcons({ ...icons, editIcon: !editIcon, deleteIcon: !deleteIcon })
          }
        />
      </FlexRow>

      <FlexRow flex={1} justify='space-between'>
        <Text fontSize='15px' color='#00B8FC'>
          <FormattedMessage {...messages.enterPrize} />
        </Text>

        <Text fontSize='15px' color='#00B8FC'>
          <FormattedMessage {...messages.serviceProviders} />
        </Text>
      </FlexRow>
    </StyleWrapper>
  );
};

export default ProposalContent;
