/**
 *
 * GrahpQlProof
 *
 */

import styled from "styled-components";

export const StyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  flex-wrap: wrap;
`;
