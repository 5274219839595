import React, { Fragment } from 'react';
import DefaultProvider from './DefaultProvider';
import TouchProvider from './TouchProvider';

const isTouchDevice = (): boolean => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

const Provider: React.FC<ProposalGridProps> = ({ bricks }) => {
  if (!bricks) {
    return null;
  }

  return (
    <Fragment>
      {isTouchDevice() ? (
        <TouchProvider bricks={bricks} />
      ) : (
        <DefaultProvider bricks={bricks} />
      )}
    </Fragment>
  );
};

export default Provider;
