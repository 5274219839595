/*
 * ProofOfContext Messages
 *
 * This contains all the text for the ProofOfContext component.
 */

import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    id: "app.components.ProofOfContext.header",
    defaultMessage: "Context Proof",
  },
  udpate: {
    id: "app.components.ProofOfContext.udpate",
    defaultMessage: "Update Context!",
  },
});
