/**
 *
 * SubHeaderDesktop
 *
 */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mediaQueries } from '../../utils/styledTheme';

interface IProps {
  matchedRoute: boolean;
}

export const StyleWrapper = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #707070;
  height: 177px;
  background-color: white;
  position: relative;

  ${mediaQueries.desktop} {
    display: flex;
  }
`;

export const Container = styled.div`
  height: 100%;
  width: 93.75%;
  display: flex;
  align-items: center;
`;

export const Links = styled.div`
  max-width: 1038px;
  width: 100%;
  height: 29px;
  margin-left: 9.7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    font-size: 21px;
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export const StyledLink = styled(Link)<IProps>`
  color: ${(props) => (props.matchedRoute ? '#00B8FC' : 'black')};

  &:hover {
    color: #00b8fc;
  }
`;

export const SubHeaderContainer = styled.div`
  width: 1314px;
  height: 64px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;
