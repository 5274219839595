/*
 * ContentManagementContainer Messages
 *
 * This contains all the text for the ContentManagementContainer component.
 */

import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    id: "app.containers.ContentManagementContainer.header",
    defaultMessage: "This is ContentManagementContainer container !",
  },
});
