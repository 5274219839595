/*
 * SendProposalButton Messages
 *
 * This contains all the text for the SendProposalButton component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.components.SendProposalButton.header',
    defaultMessage: 'send proposal',
  },
});
