import { gql } from '@apollo/client';

export const USER_LOGIN = gql`
  mutation UserLogin(
    $username: String
    $password: String
    $rememberMe: Boolean!
  ) {
    userLogin(
      input: {
        username: $username
        password: $password
        rememberMe: $rememberMe
      }
    ) {
      loginDetails {
        accessToken
        refreshToken
      }
    }
  }
`;
