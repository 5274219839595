import React from 'react';
import { FileInputProps } from './types';
import { FileInput, InputWrapper, Label } from './styles';

const FileInputHelper = ({ label, setFieldValue }: FileInputProps) => {
  return (
    <InputWrapper width='49%' padding='0 5%'>
      <Label>{label}</Label>
      <FileInput
        id='file'
        name='file'
        type='file'
        onChange={(e: any) => setFieldValue('file', e.currentTarget.files[0])}
      />
    </InputWrapper>
  );
};

export default FileInputHelper;
