/**
 *
 * Login
 *
 */
import React from 'react';
import LoginModal from '../../components/AuthModal/loginModal';
import Background from '../../components/Background/index';
import imageUrl from '../../images/salesBackground/salesBackground.jpg';

interface LoginProps {}

const Login = ({}: LoginProps) => (
  <>
    <Background imageUrl={imageUrl} />
    <LoginModal />
  </>
);

export default Login;
