/**
 *
 * SubHeaderIpad
 *
 */

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { constants, fonts, mediaQueries } from '../../utils/styledTheme';
import {
  MenuItemWrapperProps,
  DropDownItemProps,
  ArrowProps,
  StyleWrapperProps,
} from './types';

export const StyleWrapper = styled.div<StyleWrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 136px;
  background: #ffffff;

  border-bottom: ${(props) => props.borderBottom};

  @media (min-width: ${constants.mediaQueries.desktop}px) {
    display: none;
  }
`;

export const Container = styled.div`
  width: 91%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled(motion.button)`
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
  margin-right: 28px;
`;

export const MenuWrapper = styled(motion.div)`
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 10.2vh);
  opacity: 0.5;

  @media (min-width: ${constants.mediaQueries.desktop}px) {
    display: none;
  }
`;

export const MenuItemWrapper = styled.div<MenuItemWrapperProps>`
  text-decoration: none;
  font-size: 24px;
  font-family: ${fonts.bold};
  margin-bottom: ${(props) => props.marginBottom || '40px'};
  color: ${(props) => (props.matchedRoute ? '#00B8FC' : '#000000')};
  text-transform: uppercase;
  font-family: ${fonts.bold};
  cursor: pointer;
`;

export const SubItemsWrapper = styled.div`
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  margin-bottom: 40px;
`;

export const Label = styled.div`
  position: relative;
  font-size: 24px;
  font-family: ${fonts.bold};
  text-transform: uppercase;
  color: #707070;
  padding: 0 2rem;
`;

export const Arrow = styled.div<ArrowProps>`
  transform-origin: center;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.209' height='12.933' viewBox='0 0 20.209 12.933'%3E%3Cg id='Component_84_1' data-name='Component 84 – 1' transform='translate(2.828 2.828)'%3E%3Cpath id='Path_31070' data-name='Path 31070' d='M-17406.768,2301l7.275,7.276,7.277-7.276' transform='translate(-17392.215 2308.276) rotate(180)' fill='none' stroke='%23707070' stroke-linecap='round' stroke-width='4'/%3E%3C/g%3E%3C/svg%3E");
  width: 21px;
  height: 13px;
  cursor: pointer;
  transform: ${(props) => props.transform};
`;

export const DropDown = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DropDownItem = styled.div<DropDownItemProps>`
  cursor: pointer;
  font-size: 24px;
  font-family: ${fonts.bold};
  text-transform: uppercase;
  color: #000000;
  margin: 22px 0;
  color: ${(props) => (props.matchedRoute ? '#00B8FC' : '#000000')};
`;

export const SubHeaderContainer = styled.div`
  width: 740px;
  height: 64px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
  }
`;
