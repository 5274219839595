/**
 *
 * ProposalsContainer
 *
 */
 import React, {useState} from "react";
 import { FormattedMessage } from "react-intl";
 import { ContentArea } from "../../layout/ContentArea";
 import Button from "../../components/Button";
 import messages from "./messages";
 import useNavigate from "../../utils/hooks/useNavigate";
 import {Container, Grid, MenuItem, Box, Select, FormControl, InputLabel, Typography, SvgIcon, IconButton} from '@material-ui/core'
 import FormikHandler from "../../components/FormikHandler";
 import Table from '../../components/TableProof'
 import {Title, StyledButton, StyledTableCell, StyledTableRow} from './styles'
 import {ReactComponent as Settings} from '../../images/svg/SettingsIcon.svg'
 import {ReactComponent as Share} from '../../images/svg/ShareIcon.svg'
 import {ReactComponent as Snapshot} from '../../images/svg/SnapshotIcon.svg'
 import {ReactComponent as View} from '../../images/svg/ViewIcon.svg'
 import {ReactComponent as Delete} from '../../images/svg/DeleteIcon.svg'
 import {ReactComponent as Edit} from '../../images/svg/EditIcon.svg'
import CustomTooltip from "../../components/CustomTooltip";
import TooltipContent from '../../components/TooltipContent'
 
 interface ProposalsContainerProps {}
 
 const ProposalsContainer = ({}: ProposalsContainerProps) => {

  const [snapshotOpen, setSnapshotOpen] = useState<any>(null)
  const navigate = useNavigate();

  const generateHeaders = () => [
    {title: "Proposal Name"},
    {title: "Status"},
    {title: "Created by"},
    {title: "Date Sent"},
    {title: "Sent to"},
    {title: "Company"},
    {title: ""}
  ]

  const generateRows = () => [
    {
      proposalName: "AAAAAAA",
      status: "Draft",
      createdBy: "Simon Jones",
      lastEdited: new Date().toLocaleDateString("en-UK"),
      lastEditedBy: "Matthew Simpson",
      createdAt: new Date().toLocaleDateString("en-UK"),
      dateSent: new Date().toLocaleDateString("en-UK"),
      sentTo: [
        {name: "Garrett Doyle", jobTitle: "CEO"},
        {name: "Simon Kerr", jobTitle: "Head of Purchasing"}
      ],
      company: "The Virtual Forge",
      version: (Math.random() * 100).toFixed(0)
    },
    {
      proposalName: "Proposal name goes here",
      status: "Draft",
      createdBy: "Simon Jones",
      lastEdited: new Date().toLocaleDateString("en-UK"),
      lastEditedBy: "Matthew Simpson",
      createdAt: new Date().toLocaleDateString("en-UK"),
      dateSent: new Date().toLocaleDateString("en-UK"),
      sentTo: [
        {name: "Garrett Doyle", jobTitle: "CEO"},
        {name: "Simon Kerr", jobTitle: "Head of Purchasing"}
      ],
      sharedWith: [
        {name: "Samantha Collingwood", email: "samantha.collingwood@thevirtualforge.com"}
      ],
      company: "The Virtual Forge",
      version: (Math.random() * 100).toFixed(0)
    }
  ]

   return (
       <ContentArea>
         <Container component={Box} sx={{mt: 2}}>
           <Grid container spacing={1}>
             <Grid item xs={8}>
               <Title variant="h2">Your proposals</Title>
             </Grid>
             <Grid item xs={4}>
               <StyledButton onClick={() => navigate.proposalsCreate()}>Create proposal</StyledButton>
             </Grid>
             <Grid item xs={8}>
               <FormikHandler
                  initialValues={{emails: []}}
                  name="emails"
                  handleSubmit={(values: any) => console.log(values)}
                  placeholder="Search by Proposal, Client or Company Name"
               />
             </Grid>
             <Grid item xs={4}>
             </Grid>
             <Grid item xs={12}>
               <Table
                  headers={generateHeaders()}
                  tableData={generateRows()}
                  renderBody={(row, index) => (
                      <StyledTableRow>
                        <StyledTableCell>{row.proposalName}</StyledTableCell>
                        <StyledTableCell>{row.status}</StyledTableCell>
                        <StyledTableCell>{row.createdBy}</StyledTableCell>
                        <StyledTableCell>{row.dateSent}</StyledTableCell>
                        <StyledTableCell>{row.sentTo.map((item: any) => (
                          <Typography>{`${item.name}, ${item.jobTitle}`}</Typography>
                        ))}</StyledTableCell>
                        <StyledTableCell>{row.company}</StyledTableCell>
                        <StyledTableCell>
                          <IconButton>
                            <SvgIcon component={View} viewBox="0 0 24 24" />
                          </IconButton>
                          <IconButton>
                            <SvgIcon component={Share} viewBox="0 0 24 24" />
                          </IconButton>
                          <CustomTooltip
                            open={snapshotOpen === index}
                            title={
                              <React.Fragment>
                                <TooltipContent
                                  onClose={() => setSnapshotOpen(null)}
                                  title={row.proposalName}
                                  subtitle={row.company}
                                  lastEdited={row.lastEdited}
                                  lastEditedBy={row.lastEditedBy}
                                  createdAt={row.createdAt}
                                  createdBy={row.createdBy}
                                  version={row.version}
                                  sentTo={row.sentTo}
                                  dateSent={row.dateSent}
                                  sharedWith={row.sharedWith}
                                />
                              </React.Fragment>
                            }
                          >
                            <IconButton onClick={() => setSnapshotOpen(index)}>
                              <SvgIcon component={Snapshot} viewBox="0 0 24 24" />
                            </IconButton>
                          </CustomTooltip>
                          <IconButton>
                            <SvgIcon component={Edit} viewBox="0 0 24 24" />
                          </IconButton>
                          <IconButton>
                            <SvgIcon component={Delete} viewBox="0 0 24 24" />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                  )}
               />
             </Grid>
           </Grid>
         </Container>
       </ContentArea>
   );
 };
 
 export default ProposalsContainer;
 