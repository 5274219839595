/**
*
* ExampleContext
* ExampleProvider
*
* TODO: 1. go to types/ExampleContext.d.ts -> update types
* TODO: 2. go to metaProvider -> add import(see below) -> include provider to composer
import ExampleProvider from "./ExampleContext";
* TODO: 3. Add the below lines to a component that needs context.
import { ExampleContext } from 'context/ExampleContext';
* Put this at the top of a React component.
const { counter, updateCounter } = useContext(ExampleContext) as ExampleContextType;

*/

import React, { useState, ReactNode, createContext } from "react";

export const ExampleContext = createContext(null as ExampleContextType | null);
const ctx = ExampleContext;

const ExampleProvider: React.FC<ReactNode> = ({ children }) => {
  /** @example code */
  const [counter, setCounter] = useState(0 as number);
  const updateCounter = (count: number) => setCounter(count);

  const props = { counter, updateCounter };
  return <ctx.Provider value={props}>{children}</ctx.Provider>;
};

export default ExampleProvider;
