/*
 * GrahpQlProof Messages
 *
 * This contains all the text for the GrahpQlProof component.
 */

import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    id: "app.components.GrahpQlProof.header",
    defaultMessage:
      "This component proofs out Intl and GraphQL async calls are working",
  },
});
