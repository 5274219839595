/**
*
* HardwareComponents
*
*/

import React, {useContext} from 'react';
import { Props } from "./types";
import { StyleWrapper, Title } from './styles'
import ProposalContext from '../../containers/ViewProposalContainer/propsalContext';
import { ShopDisplay } from './ShopDisplay';


function HardwareComponents({}: Props) {

  const state: any = useContext(ProposalContext)

  const {selectedRoom} = state

  console.log(selectedRoom)

  return (
    <StyleWrapper>
      {selectedRoom && (
        <React.Fragment>
          <Title>{selectedRoom?.name} rooms <span style={{color: "#000"}}>AT</span> The Virtual Forge</Title>
          {selectedRoom?.videoSolutions?.length ? <ShopDisplay type="Video solutions" shopItems={selectedRoom?.videoSolutions} /> : null}
          {selectedRoom?.byo?.length ? <ShopDisplay type="BYO" shopItems={selectedRoom?.byo} /> : null}
          {selectedRoom?.audio?.length ? <ShopDisplay type="Audio" shopItems={selectedRoom?.audio} /> : null}
          {selectedRoom?.accessories?.length ? <ShopDisplay type="Accessories" shopItems={selectedRoom?.accessories} /> : null}
        </React.Fragment>
      )}
    </StyleWrapper>
  );
}

export default HardwareComponents;