import React, {FunctionComponent} from 'react';
import {ApolloClient, InMemoryCache, ApolloProvider, gql} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { cache } from './cache';

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

const isDev = process.env.NODE_ENV !== 'production';

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
        graphQLErrors.map(({message, locations, path, extensions}) => {
                if (isDev) {
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                        {message, locations, path, extensions}
                    )
                }

                if (message === "Token has expired") {
                    // put logic here to request new token
                }

                if (extensions?.exception?.statusCode === 401) {
                    // @@TODO reroute to home if 401
                    // history.push("/");
                }

                // eslint-disable-next-line no-console
                if (networkError && isDev) console.log(`[Network error]: ${networkError}`);
            },
        )
});

const httpLink = new BatchHttpLink({
    uri: isDev ? process.env.REACT_APP_API_ENDPOINT : "#{API_URL}",
    includeExtensions: true,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});


const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache,
    typeDefs,
    connectToDevTools: true,
});


const ApolloClientWrapper: FunctionComponent = ({children}) => {
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )

}
export default ApolloClientWrapper;
