/*
 * UsersContainer Messages
 *
 * This contains all the text for the UsersContainer component.
 */

import { defineMessages } from "react-intl";

export default defineMessages({
  header: {
    id: "app.containers.UsersContainer.header",
    defaultMessage: "User Management",
  },
  createUser: {
    id: "app.containers.UsersContainer.createUser",
    defaultMessage: "Create User",
  },
});
