/**
 *
 * FormikProof
 *
 */

import styled from "styled-components";

export const StyleWrapper = styled.div`
  display: flex;
  background-color: grey;
`;
