/**
 *
 * FormikProof
 *
 */

import React from "react";
import { Props } from "./types";
import { StyleWrapper } from "./styles";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import {
  Formik,
  FormikHelpers,
  FormikProps,
  Form,
  Field,
  FieldProps,
} from "formik";
import CreateUser from "../Forms/CreateUser";

interface MyFormValues {
  firstName: string;
}

function FormikProof({}: Props) {
  const initialValues: MyFormValues = { firstName: "" };
  return (
    <StyleWrapper>
      <CreateUser projectName={"dummyProject"} />
    </StyleWrapper>
  );
}

export default FormikProof;
