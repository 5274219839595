/**
 *
 * InlineTitleField
 *
 */

import React, {useState, useMemo} from 'react';
import {Props} from "./types";
import {Input} from './styles'
import {TextFieldProps} from '@material-ui/core/TextField'
import {ReactComponent as Edit} from "../../../images/svg/EditIcon.svg";
import styled from "styled-components";
import {EditProposalInterface} from "../../Forms/EditProposal/formik";

const InlineTitleField: React.FC<Props & TextFieldProps> = (props) => {
    const [edit, setEdit] = useState(true);

    const calcFieldWidth = () => {
        const { value } = props;

        // @ts-ignore
        if (value?.length === null || !!!value) {
            return "0ch";
        }

        // @ts-ignore
        return `${value.length + 2.3}ch`
    }


    return (
        <Wrapper>
            <Input {...props} disabled={edit} width={calcFieldWidth()}/>
            <Edit onClick={() => setEdit(!edit)}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  
  > svg {
    position: relative;
    width: 30px;
    height: 30px;
    right: 34px;
    top: 2px;
  }
`;

export default InlineTitleField;
