/**
 *
 * Background
 *
 */

import styled from 'styled-components';

import { StyleWrapperProps, ContainerProps, TextProps } from './types';
import { mediaQueries } from '../../utils/styledTheme';

export const StyleWrapper = styled.div<StyleWrapperProps>`
  background-size: cover;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-repeat: no-repeat;
  width: 100%;
  min-height: 913px;
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const Container = styled.div<ContainerProps>`
  margin-top: ${(props) => props.marginTop};
  height: auto;
  width: 93.75%;
`;

export const Title = styled.div`
  font-size: 111px;
  width: fit-content;
  width: -moz-fit-content;
  height: 244px;
  font-weight: 700;
  font-family: BrownProTTBold;
  max-width: 100vw;

  span {
    font-family: BrownProTTLight;
    font-weight: 100;
  }

  ${mediaQueries.desktop} {
    width: 852px;
  }
`;

export const Text = styled.div<TextProps>`
  margin-top: ${(props) => props.marginTop};
  font-size: 27px;
  font-family: BrownProTTLight;
`;
