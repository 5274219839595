// @ts-nocheck
import React from 'react';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { usePreview } from 'react-dnd-preview';
import ProposalBricks from './ProposalGrid';
import { Preview, Title } from './styles';

let opts = {};

const MyPreview = () => {
  const { display, item, style } = usePreview();
  if (!display) {
    return null;
  }

  const { colourCode, name } = item;

  return (
    <Preview background={colourCode} style={style}>
      <Title>{name}</Title>
    </Preview>
  );
};

const TouchProvider: React.FC<ProposalGridProps> = ({ bricks }) => (
  <DndProvider backend={TouchBackend} options={opts}>
    <ProposalBricks bricks={bricks} />
    <MyPreview />
  </DndProvider>
);

export default TouchProvider;
