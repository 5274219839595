/**
 *
 * MultiSelect
 *
 */

import styled from 'styled-components';
import { ArrowProps, OptionProps } from './types';

export const grey = '#E1E2E3';

export const StyleWrapper = styled.div`
  margin-top: 10px;
  display: block;
  //width: 606px;
  margin-left: auto;
`;

export const ArrowContainer = styled.div`
  width: 56px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Arrow = styled.div<ArrowProps>`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='10' viewBox='0 0 18 10'%3E%3Cpath id='Polygon_1' data-name='Polygon 1' d='M9,0l9,10H0Z' transform='translate(18 10) rotate(180)'/%3E%3C/svg%3E%0A");
  width: 18px;
  height: 10px;
  cursor: pointer;
  transform: ${(props) => props.transform};
`;

export const StyledOption = styled.div<OptionProps>`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 30px;
  margin: 0;
  border-radius: ${(props) => props.borderRadius || '0px'};

  &:hover {
    background: ${grey};
  }
`;
