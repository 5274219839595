/**
 *
 * AuthModal
 *
 */

import styled from 'styled-components';
import { InputProps, SubmitProps } from './types';
import { Form, Field } from 'formik';
import { motion } from 'framer-motion';

export const grey = '#E1E2E3';
export const fontColor = '#707070';
export const blue = '#265FC5';
export const lightBlue = '#4fb7f6';

export const Label = styled.div`
  margin: 24px auto 35px;
  width: fit-content;
  color: ${fontColor};
`;

export const FormStyled = styled(Form)`
  width: 606px;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const Input = styled(Field)<InputProps>`
  all: unset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 72px;
  margin-top: ${(props) => props.marginTop};
  border-radius: 5px;
  border: 2px solid ${grey};
  padding-left: 5%;
  padding-right: 5%;
  color: ${fontColor};

  &:focus {
    border: 3px solid ${blue};
  }
`;

export const CheckBoxWrapper = styled.div`
  width: 100%;
  height: 78px;
  display: flex;
  align-items: center;
`;

export const CheckBox = styled.input`
  all: unset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid ${grey};
  background: white;
  cursor: pointer;

  &:checked {
    background-image: url("data:image/svg+xml,%3Csvg id='Group_24749' data-name='Group 24749' xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Crect id='Rectangle_18' data-name='Rectangle 18' width='28' height='28' rx='5' fill='%2300b8fc'/%3E%3Cpath id='Path_3' data-name='Path 3' d='M5.36,14l6.5,5.869L22.437,6.475' fill='none' stroke='%23fff' stroke-width='4'/%3E%3C/svg%3E%0A");
    border: 0px solid ${grey};
  }
`;

export const Text = styled.div`
  font-size: 20px;
  margin-left: 3.6%;
  color: ${fontColor};

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Forgot = styled.div`
  display: block;
  font-size: 20px;
  margin-left: auto;
  color: ${fontColor};
`;

export const Submit = styled(motion.input)<SubmitProps>`
  all: unset;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 77px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  display: block;
  margin-top: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-transform: uppercase;
`;

export const customStylesLogin = {
  content: {
    maxWidth: 820,
    margin: 'auto',
    height: 683,
    width: '70%',
    borderRadius: 0,
    background: '#ffff',
    padding: 0,
    onset: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 0,
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.9)',
    padding: 0,
    overflow: 'visible',
    onset: 0,
    zIndex: 999999999999,
  },
};

export const customStylesPasswordReset = {
  content: {
    maxWidth: 820,
    margin: 'auto',
    height: 579,
    width: '70%',
    borderRadius: 0,
    background: '#ffff',
    padding: 0,
    onset: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 0,
  },

  overlay: {
    backgroundColor: 'rgba(51, 51, 51, 0.9)',
    padding: 0,
    overflow: 'visible',
    onset: 0,
    zIndex: 999999999999,
  },
};
