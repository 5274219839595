import React from 'react';
import { IntlProvider } from 'react-intl';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { MetaProvider } from './context/metaProvider';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';

import appTheme from './appTheme';
import ApolloClientWrapper from './apollo/ApolloClientWrapper';
import ScrollToTop from "./utils/ScrollToTop";

interface Props {}

const AppWrapper: React.FC<Props> = ({ children }) => (
  <MuiThemeProvider theme={appTheme}>
    <MetaProvider>
      {/*// @@TODO this is to be expanded once we are beyond MVP to support other languages*/}
      <IntlProvider locale={'EN'}>
        <BrowserRouter>
            <ScrollToTop/>
          <ApolloClientWrapper>{children}</ApolloClientWrapper>
        </BrowserRouter>
      </IntlProvider>
    </MetaProvider>
  </MuiThemeProvider>
);

export default AppWrapper;
