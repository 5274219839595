/**
*
* ConfirmCancelModal
*
*/

import styled from 'styled-components';
import StyledModal from '../StyledModal';

export const StyleWrapper = styled.div`
  display: flex;
`;

export const ModalTitle = styled.h2`
  color: #393b3a;
  text-align: left;
  font-size: 36px;
  margin-bottom: 0;
`
export const ModalSubtitle = styled.p`
  color: #393b3a;
  text-align: left;
  font-size: 18px;
  margin-top: 5px;
`