/**
*
* ConfirmCancelModal
*
*/

import React from 'react';
import { Props } from "./types";
import { ModalTitle, ModalSubtitle } from './styles'
import StyledModal from '../StyledModal';
import { Container, Grid } from '@material-ui/core';
import Button from '../Button'

function ConfirmCancelModal({isOpenConfirmCancel, onRequestClose, title, subtitle, onRequestConfirm}: Props) {
  return (
    <StyledModal
      isOpen={isOpenConfirmCancel}
      onRequestClose={onRequestClose}
    >
      <ModalTitle>{title}</ModalTitle>    
      <ModalSubtitle>{subtitle}</ModalSubtitle>
      <Button onClick={onRequestClose}>Cancel</Button>
      <Button onClick={onRequestConfirm}>Yes I'm sure</Button>

      
    </StyledModal>
  );
}

export default ConfirmCancelModal;