/**
 *
 * * AddInstance Styles
 *
 */

import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";

const styles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
    },
    label: {
      fontSize: "13px",
      textAlign: "left",
      marginTop: "0.5rem",
      // marginBottom: "0.5rem",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    errorMessage: {
      height: "1.5rem",
      fontFamily: "monospace",
      fontSize: "1rem",
      textAlign: "center",
    },
    buttonStyle: {
      marginTop: "1rem",
      marginBottom: "0.5rem",
      width: "10rem",
      paddingLeft: "0.1rem",
      paddingRight: "0.1rem",
      border: "none",
    },
    formStyle: {
      width: "auto",
      marginLeft: "1.125rem",
      marginRight: "1.125rem",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    inputTypography: {
      fontFamily: "monospace !important",
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
      marginRight: "1.125rem",
      width: "17.5rem",
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
  })
);

const useStyles = () => styles(useTheme());

export default useStyles;
