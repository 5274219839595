import styled from 'styled-components';
import { mediaQueries, fonts } from '../../utils/styledTheme';

export const StyleWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  margin: 55px auto 0;
  width: 740px;
  height: auto;
  display: flex;
  flex-direction: column;

  ${mediaQueries.iPadPro} {
    width: calc(1024px - 28px);
    grid-template-columns: repeat(4, 1fr);
  }

  ${mediaQueries.desktop} {
    width: 1314px;
  }
`;

export const Review = styled.div`
  color: #00b8fc;
  font-size: 31px;
  text-transform: uppercase;
  font-family: ${fonts.bold};
`;
