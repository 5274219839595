/**
 *
 * TestComp
 *
 */

import React from "react";
import { Props } from "./types";
import { StyleWrapper } from "./styles";
import { FormattedMessage } from "react-intl";
import messages from "./messages";

function TestComp({}: Props) {
  return (
    <StyleWrapper>
      <FormattedMessage {...messages.header} />
    </StyleWrapper>
  );
}

export default TestComp;
