/**
*
* FileDrop
*
*/

import React, {useEffect} from 'react';
import {FormikProps, FormikValues, useFormikContext} from 'formik'
import { FiledropLabelWrapper, FiledropLabel, FiledropWrapper, FileInput, ImgPreview } from './styles'
import {CustomFieldProps, Props} from './types'
import {ReactComponent as UploadIcon} from '../../images/svg/UploadIcon.svg'
import {SvgIcon} from '@material-ui/core'

function FileDrop(props: CustomFieldProps) {

  const {values, setFieldValue}: FormikProps<FormikValues> = useFormikContext()

  useEffect(() => {
    const reader = new FileReader()
    reader.onloadend = () => {
      setFieldValue("imgPreview", reader.result)
    }
    if(typeof values.organisationLogo === "object" && props.enablePreview){
      reader.readAsDataURL(values.organisationLogo)
    }
  }, [values.organisationLogo])

  const {enablePreview, ...rest} = props

  return (
    <>
      <label htmlFor={props.htmlFor}>
        <FiledropWrapper height={props.height}>
          <FileInput {...rest} value={undefined} accept="image/png" type="file" id={props.id} />
          {values.imgPreview && enablePreview
            ? (
              <ImgPreview src={values.imgPreview} />
            ) : (
              <FiledropLabelWrapper>
                <SvgIcon component={UploadIcon} viewBox="0 0 48 48" />
                <FiledropLabel>upload your logo or a PNG file</FiledropLabel>
              </FiledropLabelWrapper>
            )
          }
        </FiledropWrapper>
      </label>
    </>
  );
}

export default FileDrop;