import { makeNavigate } from "../helperFunctions/makeRouteMap";
import { routeMap } from "../../routes";
import { useHistory } from "react-router-dom";

const useNavigate = () => {
  const history = useHistory();
  const navigate = makeNavigate(routeMap, history.push);
  return navigate;
};

export default useNavigate;
