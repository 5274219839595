import { SvgIcon } from '@material-ui/core'
import React from 'react'
import {ShopItemImg, DisplayItem, CheckBoxWrapper, CheckBox, Text, InfoWrapper, ShopItemInfo} from './styles'
import {ShopItem as ShopItemT} from './types'

export const ShopItem = ({imageUrl, name, info}: ShopItemT) => {
    return (
        <DisplayItem>
            <ShopItemImg src={imageUrl} />
            <CheckBoxWrapper>
                <CheckBox type='checkbox' name='rememberMe' id='rememberMe'/>
                <Text>{name}</Text>
            </CheckBoxWrapper>
            <ShopItemInfo>
                {info?.map((info, index) => (
                    <InfoWrapper key={`shopiteminfo-${index}`}>
                        <SvgIcon component={info.icon} viewBox="0 0 32 32" />
                        {info.name}
                    </InfoWrapper>
                ))}
            </ShopItemInfo>
        </DisplayItem>
    )
}