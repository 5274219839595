import * as Yup from 'yup'

export const createOrganisationSchema = Yup.object().shape({
    organisationName: Yup.string().required('Required field').min(3, 'Must be 3 at least 3 chararacters long'),
    region: Yup.string().required('Required field')
})

export interface SelectInterface {
    label: string;
    value: string;
}

export interface CreateOrgInterface {
    organisationName: string;
    region: SelectInterface | undefined;
    organisationLogo?: undefined,
    organisationDomains?: Array<SelectInterface> | undefined,
    imgPreview?: any
}

export const initialValues: CreateOrgInterface = {
    organisationName: "",
    region: undefined,
    organisationDomains: undefined,
    organisationLogo: undefined,
    imgPreview: undefined
}

export default createOrganisationSchema

export const regions = [
    "Region A",
    "Region B",
    "Region C"
]