/**
 *
 * * AddInstance
 *
 */

import React from "react";

// Components
import { FormattedMessage } from "react-intl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { Form, Formik, FormikProps } from "formik";

// Utils
import messages from "./messages";
import useStyles from "./styles";
import { IFormValues, initialValues, InstanceSchema } from "./formik";

export const CreateUser = ({ projectName }: AddInstanceProps) => {
  const classes = useStyles();

  const onSubmit = async (arg: any) => {
    alert(JSON.stringify(arg));
  };

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.wrapper}
    >
      <Grid item xs={12}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // @ts-ignore
          validationSchema={InstanceSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            isSubmitting,
            isValid,
            errors,
            touched,
          }: FormikProps<IFormValues>) => (
            <Form autoComplete="off" noValidate className={classes.formStyle}>
              <FormControl>
                <FormattedMessage {...messages.newName}>
                  {(newName) => (
                    <Typography variant="h6" className={classes.label}>
                      {newName}
                    </Typography>
                  )}
                </FormattedMessage>
                <TextField
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  className={classes.inputTypography}
                />
                {errors.name && touched.name && (
                  <Typography
                    variant="caption"
                    color="error"
                    className={classes.errorMessage}
                  >
                    {errors.name && touched.name && errors.name}
                  </Typography>
                )}
              </FormControl>
              <FormControl>
                <FormattedMessage {...messages.fqdn}>
                  {(fqdn) => (
                    <Typography variant="h6" className={classes.label}>
                      {fqdn}
                    </Typography>
                  )}
                </FormattedMessage>
                <TextField
                  name="fqdn"
                  value={values.fqdn}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  className={classes.inputTypography}
                />
                {errors.fqdn && touched.fqdn && (
                  <Typography
                    variant="caption"
                    color="error"
                    className={classes.errorMessage}
                  >
                    {errors.fqdn && touched.fqdn && errors.fqdn}
                  </Typography>
                )}
              </FormControl>

              <FormControl>
                <FormattedMessage {...messages.platform}>
                  {(platform) => (
                    <Typography variant="h6" className={classes.label}>
                      {platform}
                    </Typography>
                  )}
                </FormattedMessage>
                <Select
                  value={values.platform}
                  name="platform"
                  // @ts-ignore
                  onChange={handleChange}
                  className={classes.inputTypography}
                >
                  <MenuItem value=" " disabled selected>
                    <FormattedMessage {...messages.selectPlatformPlaceholder}>
                      {(selectPlatformPlaceholder) => (
                        <Typography variant="h6" className={classes.label}>
                          {selectPlatformPlaceholder}
                        </Typography>
                      )}
                    </FormattedMessage>
                  </MenuItem>
                </Select>

                {errors.platform && touched.platform && (
                  <Typography
                    variant="caption"
                    color="error"
                    className={classes.errorMessage}
                  >
                    {errors.platform && touched.platform && errors.platform}
                  </Typography>
                )}
              </FormControl>

              <FormControl>
                <FormattedMessage {...messages.gcpProject}>
                  {(gcpProject) => (
                    <Typography variant="h6" className={classes.label}>
                      {gcpProject}
                    </Typography>
                  )}
                </FormattedMessage>
                <Select
                  value={values.gcpProject}
                  name="gcpProject"
                  // @ts-ignore
                  onChange={handleChange}
                  className={classes.inputTypography}
                >
                  <MenuItem value=" " disabled selected>
                    <FormattedMessage {...messages.selectGcpProjPlaceholder}>
                      {(selectGcpProjPlaceholder) => (
                        <Typography variant="h6" className={classes.label}>
                          {selectGcpProjPlaceholder}
                        </Typography>
                      )}
                    </FormattedMessage>
                  </MenuItem>
                </Select>
                {errors.gcpProject && touched.gcpProject && (
                  <Typography
                    variant="caption"
                    color="error"
                    className={classes.errorMessage}
                  >
                    {errors.gcpProject &&
                      touched.gcpProject &&
                      errors.gcpProject}
                  </Typography>
                )}
              </FormControl>
              <FormControl>
                <FormattedMessage {...messages.zone}>
                  {(zone) => (
                    <Typography variant="h6" className={classes.label}>
                      {zone}
                    </Typography>
                  )}
                </FormattedMessage>
                <TextField
                  name="zone"
                  value={values.zone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  className={classes.inputTypography}
                />
                {errors.zone && touched.zone && (
                  <Typography
                    variant="caption"
                    color="error"
                    className={classes.errorMessage}
                  >
                    {errors.zone && touched.zone && errors.zone}
                  </Typography>
                )}
              </FormControl>
              <FormControl>
                <FormattedMessage {...messages.instanceId}>
                  {(instanceId) => (
                    <Typography variant="h6" className={classes.label}>
                      {instanceId}
                    </Typography>
                  )}
                </FormattedMessage>
                <TextField
                  name="instanceId"
                  value={values.instanceId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  className={classes.inputTypography}
                />
                {errors.instanceId && touched.instanceId && (
                  <Typography
                    variant="caption"
                    color="error"
                    className={classes.errorMessage}
                  >
                    {errors.instanceId &&
                      touched.instanceId &&
                      errors.instanceId}
                  </Typography>
                )}
              </FormControl>

              <div className={classes.buttonContainer}>
                <FormattedMessage {...messages.submitLabel}>
                  {(submitLabel) => (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                      className={classes.buttonStyle}
                    >
                      {submitLabel}
                    </Button>
                  )}
                </FormattedMessage>
              </div>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export interface AddInstanceProps {
  projectName: string;
}

export default CreateUser;
