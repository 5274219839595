import {TextField, makeStyles, createStyles} from '@material-ui/core'
import styled from 'styled-components'
import appTheme from '../../../appTheme'
import {blue, fontColor, grey} from '../TextField/styles'
// @ts-ignore
import BrownProTT from '../../../fonts/BrownProTT/BrownProTT-Regular.ttf';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            border: `2px solid ${grey}`,
            height: "72px",
            marginTop: "10px",
        },
        input: {
            color: fontColor,
            fontFamily: "BrownProTT",
            fontSize: "16px",
            fontWeight: "normal",
            marginLeft: "7px",

            '&::placeholder': {
                textOverflow: 'ellipsis !important',
                color: "#707070",
                opacity: 1,

            },


        },
        paper: {
            backgroundColor: "#fff"
        },
        listbox: {
            padding: "0px",
            margin: "0px",
            border: "solid 2px #E1E2E3",
        },
        option: {
            // backgroundColor: "white",
            color: "#707070",
            height: "60px",
            fontSize: "16px",

            '&:hover, &:focus, &:active, &[data-focus="true"]': {
                background: '#F7F7F7'
            },
        },
        noOptions: {
           display: 'none',
        },
        endAdornment: {
            display: "none",
        },
    })
)

export const useChipStyles = makeStyles(() =>
    createStyles({
        root: {
            backgroundColor: "#00B8FC",
            // height: "44px",
            fontSize: "18px",
        },
        label: {
            overflow: "visible",
        },
        deleteIcon: {
            color: "white",
        },
    })
)
