/**
*
* TooltipContent
*
*/

import styled from 'styled-components';
import {Grid as DefaultGrid} from '@material-ui/core'

export const StyleWrapper = styled.div`
  display: flex;
`;

export const SnapshotTitle = styled.h6`
     font-size: 14px;
     color: #000000;
     text-align: left;
     margin: 0.25rem;
     margin-bottom: 0;
     text-transform: uppercase;
`
export const SnapshotSubtitle = styled.h6`
     font-size: 14px;
     color: #00b8fc;
     text-align: left;
     margin: 0.25rem;
     margin-top: 0;
     text-transform: uppercase;
`
export const SnapshotCloseButton = styled.button`
    width: 100%;
    height: 11px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.125rem;
    margin-top: 0.20rem;
    border: none !important;
    background-color: transparent;
    cursor: pointer;
`
export const Grid = styled(DefaultGrid)`
  
`

export const TooltipLabel = styled.p`
  font-size: 12px;
  color: #333;
  margin: 0;
  padding-left: 0.25rem;
  font-weight: 150;
  white-space: nowrap;
`
export const TooltipText = styled.p`
  font-size: 12px;
  color: #333;
  margin: 0;
  padding-left: 0.25rem;
  font-weight: 300;
  white-space: nowrap;
`