import React from 'react'
import {DisplayTitle} from './styles'
import {Grid, Box} from '@material-ui/core'
import {ShopDisplayI} from './types'
import {ShopItem} from './ShopItem'

export const ShopDisplay = ({type, shopItems}: ShopDisplayI) => {
    return (
        <Grid component={Box} sx={{mb: "2rem"}} container spacing={2}>
            <Grid item xs={12}>
                <DisplayTitle>{type}</DisplayTitle>
                <hr />
            </Grid>
            <Grid item container spacing={2} xs={12}>
                {shopItems?.map((shopItem, index) => (
                    <ShopItem key={`shopdisplayitem-${index}`} imageUrl={shopItem.imageUrl} name={shopItem.name} info={shopItem.info} />
                ))}
            </Grid>
        </Grid>
    )
}