export const mockOrganisationData = [
    {value: "theVirtualForge", label: "The Virtual Forge"},
    {value: "logitech", label: "Logitech"},
];

export const mockDomains = [
    {
        id: "theVirtualForge",
        options: [
            {value: "@thevirtualforge.com", label: "@thevirtualforge.com"},
            {value: "@vf.com", label: "@vf.com"},
        ],
    },
    {
        id: "logitech",
        options: [
            {value: "@logitech.com", label: "@logitech.com"},
        ],
    },
]



export const sleep = (delay = 0) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}


export const fetchMockOrgs = async () => {
    await sleep(1e3); // For demo purposes;
    return mockOrganisationData;
}
