/*
 * Background Messages
 *
 * This contains all the text for the Background component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  welcome: {
    id: 'app.components.Background.welcome',
    defaultMessage: 'WELCOME TO Sales',
  },

  view: {
    id: 'app.components.Background.view',
    defaultMessage: 'View',
  },
});
