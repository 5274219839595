import * as Yup from "yup";

// @@ TODO - add text to messages
export const InstanceSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Name Too Short!")
    .max(50, "Name Too Long!")
    .required("Name required"),
  fqdn: Yup.string()
    .min(1, "FDQN Too Short!")
    .max(50, "FDQN Too Long!")
    .required("FQDN required"),
  platform: Yup.string()
    .min(2, "Platform required")
    .required("Platform required"),
  gcpProject: Yup.string()
    .min(2, "GCP Project required")
    .required("GCP Project required"),
  zone: Yup.string()
    .min(1, "Zone Too Short!")
    .max(50, "Zone Too Long!")
    .required("Zone required"),
  instanceId: Yup.string()
    .min(1, "Instance ID Too Short!")
    .max(50, "Instance ID Too Long!")
    .required("Instance ID required"),
});

export interface IFormValues {
  fqdn: string;
  name: string;
  platform: string;
  gcpProject: string;
  zone: string;
  instanceId: string;
}

export const initialValues: IFormValues = {
  fqdn: "",
  name: "",
  platform: " ",
  gcpProject: " ",
  zone: "",
  instanceId: "",
};
