/**
 *
 * RouteWrapper
 *
 */

import styled from 'styled-components';
import { StyleWrapperProps } from './types';

export const StyleWrapper = styled.div<StyleWrapperProps>`
  flex: 1 0 auto;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: ${(props) => props.display};
`;
