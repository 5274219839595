/**
*
* CustomTooltip
*
*/

import styled from 'styled-components';
import {Tooltip, createStyles, withStyles, Theme} from '@material-ui/core'

export const StyleWrapper = styled.div`
  display: flex;
`;

export const StyledTooltip = withStyles((theme: Theme) =>
     createStyles({
         tooltip: {
             fontSize: 16,
             color: theme.palette.common.black,
             backgroundColor: "#f7f7f7",
             border: "1px solid black",
             minWidth: "400px"
         },
         arrow: {
           color: theme.palette.common.white,
           "&:before": {
             border: "1px solid black"
           }
         }
     }),
 )(Tooltip);