/**
 *
 * UsersContainer
 *
 */
import React from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { ContentArea } from "../../layout/ContentArea";
import Button from "../../components/Button";

interface UsersContainerProps {}

const UsersContainer = ({}: UsersContainerProps) => {
  return (
    <ContentArea>
      <TopWrapper>
        <Heading>
          <FormattedMessage {...messages.header} />
        </Heading>
        <Button>
          <FormattedMessage {...messages.createUser} />
        </Button>
      </TopWrapper>
    </ContentArea>
  );
};

const Heading = styled("h1")`
  font-size: 61px;
  font-weight: normal;
  text-transform: uppercase;
`;

const TopWrapper = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

export default UsersContainer;
