import * as Yup from 'yup'

export const sendProposalSchema = Yup.object().shape({
    // confidenceInfo: Yup.string().required('Required field').min(3, 'Must be 3 at least 3 characters long'),
    // customerFirstName: Yup.string().required('Required field'),
    // customerLastName: Yup.string().required('Required field')
})

export interface SelectInterface {
    label: string;
    value: string;
}

export interface SendProposalInterface {
    confidenceInfo: string | number | null;
    confidenceBuying: string | number | null;
}

export const initialValues: SendProposalInterface = {
    confidenceInfo: 1,
    confidenceBuying: 1,
}

export default sendProposalSchema

