import * as Yup from 'yup'

export const createProposalSchema = Yup.object().shape({
    name: Yup.string().required('Required field').min(3, 'Must be 3 at least 3 characters long'),
    customerFirstName: Yup.string().required('Required field'),
    customerLastName: Yup.string().required('Required field')
})

export interface SelectInterface {
    label: string;
    value: string;
}

export interface CreateProposalInterface {
    name: string | null;
    customerFirstName: string | null;
    customerLastName: string | null;
    clientEmails?: [{value: string}];
    customerEmail: string | null;
}

export const initialValues: CreateProposalInterface = {
    name: null,
    customerFirstName: null,
    customerLastName: null,
    customerEmail: null,
}

export default createProposalSchema

