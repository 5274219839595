/**
 *
 * BrickSelector
 *
 */

import React, { useState, useEffect, useContext } from 'react';
import { Props, GridItemProps } from './types';
import {
  StyleWrapper,
  Container,
  Header,
  SubHeader,
  Grid,
  Item,
  CheckBox,
  Title,
  Close,
} from './styles';

import ProposalContext from '../../containers/ViewProposalContainer/propsalContext';
import { ProposalI } from '../../containers/ViewProposalContainer/types';

import ButtonHover from '../ButtonHover';

const GridItem = ({
  brick,
  selectedBricks,
  addBrick,
  removeBrick,
}: GridItemProps) => {
  const { colourCode, name } = brick;

  const [checked, setChecked] = useState(false);

  const toggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    if (checked) {
      addBrick(brick, selectedBricks);
    } else {
      removeBrick(brick, selectedBricks);
    }
    // eslint-disable-next-line
  }, [checked]);

  return (
    <Item background={colourCode}>
      <CheckBox type='checkbox' onChange={(e) => toggle(e)} />
      {name && <Title color='white'>{name}</Title>}
    </Item>
  );
};

const BrickSelector: React.FC<Props> = ({ availableBricks }) => {
  const [selectedBricks, setSelectedBricks] = useState<any[]>([]);

  const addBrick = (brick: any, selectedBricks: any) => {
    const include = selectedBricks.includes(brick);

    if (!include) {
      setSelectedBricks((oldArray) => [...oldArray, brick]);
    }
  };

  const removeBrick = (brick: any, selectedBricks: any) => {
    const filter = selectedBricks.filter((el: any) => el.id !== brick.id);
    setSelectedBricks(filter);
  };

  const proposalContext = useContext(ProposalContext) as ProposalI;

  const { brickSelector, setBrickSelector } = proposalContext;

  useEffect(() => {
    // plz muatate me parvs
    console.log(selectedBricks);
  }, [selectedBricks]);

  if (!brickSelector) {
    return null;
  }

  return (
    <StyleWrapper>
      <Close onClick={() => setBrickSelector(false)} />
      <Container>
        <Header>SELECT FROM EXISTING Proposal Tiles</Header>
        <SubHeader>
          Choose the Proposal tile you want to add to your proposal
        </SubHeader>

        <Grid>
          {availableBricks?.map((brick) => (
            <GridItem
              key={brick.id}
              brick={brick}
              selectedBricks={selectedBricks}
              addBrick={addBrick}
              removeBrick={removeBrick}
            />
          ))}
        </Grid>

        <ButtonHover
          marginTop='20px'
          marginLeft='auto'
          width='170px'
          label='OK'
          backgroundStart='#2E3132'
          backgroundHover='#fff'
          colorStart='#fff'
          colorHover='#2E3132'
        />
      </Container>
    </StyleWrapper>
  );
};

export default BrickSelector;
