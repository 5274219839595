/**
 *
 * YourRoomSolutions
 *
 */

import styled from 'styled-components';
import { StyleWrapperProps } from './types';
import { motion } from 'framer-motion';
import { mediaQueries } from '../../utils/styledTheme';

export const YourRoomSolutionsContainer = styled(motion.div)<StyleWrapperProps>`
  margin: 30px auto 0;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 740px;
  height: 220px;
  background-size: cover;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  background-repeat: no-repeat;
  border-radius: 6px;

  ${mediaQueries.iPadPro} {
    max-width: calc(1024px - 28px);
  }

  ${mediaQueries.desktop} {
    max-width: 1314px;
    border-radius: 0px;
  }
`;

export const Title = styled.div`
  position: absolute;
  width: -moz-fit-content;
  width: fit-content;
  left: 3.27%;
  top: 100px;
  color: white;
  font-size: 22px;
  text-align: center;
  max-height: 68px;
  height: auto;
  font-family: 'BrownProTTBold';
  text-transform: uppercase;

  ${mediaQueries.desktop} {
    font-size: 31px;
  }
`;

export const EditIcon = styled(motion.div)`
  position: absolute;
  top: 19px;
  right: 15px;
  width: 29px;
  height: 23px;
  background-size: cover;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.653' height='22.93' viewBox='0 0 28.653 22.93'%3E%3Cg id='pencil' transform='translate(0.001)'%3E%3Cpath id='_253708480' d='M9.8,23.82l-.7-1.153,6.814-4.28.7,1.153a2.737,2.737,0,0,1-.867,3.734L13.473,24.7A2.648,2.648,0,0,1,9.8,23.82ZM1.8,8.114a.662.662,0,0,0-.919-.22.682.682,0,0,0-.216.933L8.392,21.514,9.529,20.8ZM7.48,4.547a.662.662,0,0,0-.919-.22.684.684,0,0,0-.216.933l7.726,12.687,1.136-.713Zm5.456,14.112L5.209,5.973a1.324,1.324,0,0,0-1.838-.44A1.365,1.365,0,0,0,2.939,7.4l7.725,12.685ZM.184,6.739a1.924,1.924,0,0,1,1.234-.258,2.814,2.814,0,0,1,.055-.417,2.692,2.692,0,0,1,1.2-1.683,2.62,2.62,0,0,1,2.018-.333,2.628,2.628,0,0,1,.364.127,2,2,0,0,1,.808-1c.036-.023.078-.024.116-.043L0,0,.093,6.81c.032-.022.058-.05.091-.071Z' transform='matrix(-0.259, -0.966, 0.966, -0.259, 4.402, 22.93)' fill='%23393b3a' fill-rule='evenodd'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
`;
