/*
 * ProposalSuccessContainer Messages
 *
 * This contains all the text for the ProposalSuccessContainer component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.ProposalSuccessContainer.header',
    defaultMessage: 'This is ProposalSuccessContainer container !',
  },
  congratulations: {
    id: 'app.containers.ProposalSuccessContainer.congratulations',
    defaultMessage: 'Congratulations',
  },
  submissionSuccessful: {
    id: 'app.containers.ProposalSuccessContainer.submissionSuccessful',
    defaultMessage: 'You have submitted your proposal successfully!',
  },
  createNewProposal: {
    id: 'app.containers.ProposalSuccessContainer.createNewProposal',
    defaultMessage: 'Create New Proposal',
  },
  viewProposal: {
    id: 'app.containers.ProposalSuccessContainer.viewProposal',
    defaultMessage: 'View Proposal',
  },
});
