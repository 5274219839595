/**
 *
 * TableProofContainer
 *
 */
 import React, { useState } from 'react';
 import {
   Container,
   Grid,
   TableRow,
   TableCell,
   withStyles,
   createStyles,
   Theme,
   IconButton,
   InputLabel as Label,
   Box,
   SvgIcon
 } from '@material-ui/core'
 import {Title} from './styles'
 import Table from '../../components/TableProof';
 import Button from '../../components/Button';
 import StyledModal from '../../components/StyledModal';
 import Asynchronous from '../../components/FormFields/AsyncSelectField';
 import CreateClientForm from '../../components/Forms/CreateClientForm';
 import {ReactComponent as Edit} from '../../images/svg/EditIcon.svg'
 import {ReactComponent as Delete} from '../../images/svg/DeleteIcon.svg'
 import {ReactComponent as Lock} from '../../images/svg/LockIcon.svg'
 import FormikHandler from '../../components/FormikHandler'
import ConfirmCancelModal from '../../components/ConfirmCancelModal';
import { useHistory } from 'react-router';
import {useQuery} from "@apollo/client";
import {SEARCH_CLIENTS} from "../../utils/gql/clients/searchClientsQuery";
import {SearchClients} from "../../utils/gql/clients/__generated__/SearchClients";

interface TableProofContainerProps{}

 const StyledTableRow = withStyles((theme: Theme) =>
     createStyles({
         root: {
             backgroundColor: theme.palette.common.white,
             '&:nth-of-type(even)': {
                 backgroundColor: theme.palette.grey[300]
             },
         }
     }),
 )(TableRow);

 const StyledTableCell = withStyles((theme: Theme) =>
     createStyles({
         root: {
             fontSize: 14,
             color: theme.palette.common.black,
             border: 'none'
         },
     }),
 )(TableCell);

 const TableProofContainer = ({}: TableProofContainerProps) => {

  const history = useHistory()
 
     const { loading, error, data } = useQuery<SearchClients>(SEARCH_CLIENTS, {variables: { searchClientsSearchTerm: "hello"}});

   const generateHeaders = () => {
     return [
       {title: "Name"},
       {title: "Email address"},
       {title: "Organisation"},
       {title: "Role"},
       {title: "Phone number"},
       {title: "Job Title"},
       {title: ""}
     ]
   }

   const generateRows = () => {
     return [
       {
         name: "Some random name",
         emailAddress: "some random status",
         organisation: "The Virtual Forge",
         role: "admin",
         phoneNumber: "999 999 999",
         jobTitle: "Web developer",
         actions: [
           {type: "Edit", component: Edit},
           {type: "Delete", component: Delete},
           {type: "Reset", component: Lock}
         ]
       },
       {
         name: "Some random name",
         emailAddress: "some random status",
         organisation: "The Virtual Forge",
         role: "Super admin",
         phoneNumber: "888 888 888",
         jobTitle: "Web developer",
         actions: [
          {type: "Edit", component: Edit},
          {type: "Delete", component: Delete},
          {type: "Reset", component: Lock}
        ]
       }
     ]
   }

   const availableActions = (actionType: string) => {
     const actions = [
       {type: "Delete", action: setDeleteClientOpen, value: deleteClientOpen},
       {type: "Reset", action: setResetPasswordOpen, value: resetPasswordOpen}
     ]
     const action = actions.find((item) => item.type === actionType)
     return action?.action(!action.value)
   }

   const [isOpen, setIsOpen] = useState<boolean>(false)
   const [deleteClientOpen, setDeleteClientOpen] = useState<boolean>(false)
   const [resetPasswordOpen, setResetPasswordOpen] = useState<boolean>(false)

   return (
     <Container component={Box} sx={{mt: 2}}>
         <pre>{JSON.stringify(data, null, 2)}</pre>
       <StyledModal onRequestClose={() => setIsOpen(false)} isOpen={isOpen}>
         <CreateClientForm 
            startCreateNewOrganisation={() => history.push('/organisations', {openModal: true})}
            onClickCancel={() => setIsOpen(false)}
         />
       </StyledModal>
       <ConfirmCancelModal
          title="Delete client?"
          subtitle="Are you sure you want to mark this client for deletion?"
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Yes I'm sure"
          onRequestClose={() => setDeleteClientOpen(false)}
          onRequestConfirm={() => console.log("deletion in progress")}
          isOpenConfirmCancel={deleteClientOpen}
       />
       <ConfirmCancelModal
          title="Reset password?"
          subtitle="Are you sure you want to reset this users password?"
          cancelButtonLabel="Cancel"
          confirmButtonLabel="Yes I'm sure"
          onRequestClose={() => setResetPasswordOpen(false)}
          onRequestConfirm={() => console.log("deletion in progress")}
          isOpenConfirmCancel={resetPasswordOpen}
       />
       <Grid container spacing={1}>
         <Grid item xs={9}>
           <Title variant="h2">Client management</Title>
         </Grid>
         <Grid item xs={3}>
           <Button onClick={() => setIsOpen(!isOpen)}>Create client</Button>
         </Grid>
         <Grid item xs={12}>
             <FormikHandler
                name="emails"
                initialValues={{emails: []}}
                handleSubmit={(values: any) => console.log(values)}
                placeholder="Search"
            />
         </Grid>
         <Grid item xs={12}>
           <Table
             headers={generateHeaders()}
             tableData={generateRows()}
             renderBody={(row, index) => (
               <StyledTableRow key={index}>
                 {Object.keys(row).filter(key => key !== "actions").map((rowProperty, index) => (
                    <>
                     <StyledTableCell key={index}>{row[rowProperty]}</StyledTableCell>
                    </>
                 ))}
                 <StyledTableCell>
                   {row.actions.map((action: {type: string, component: any}) => (
                     <IconButton onClick={() => availableActions(action.type)}>
                       <SvgIcon component={action.component} viewBox="0 0 31 31" />
                     </IconButton>
                   ))}
                 </StyledTableCell>
               </StyledTableRow>
             )}
           />
         </Grid>
         <Grid component={Box} sx={{mt: 1, mb: 2}} item xs={12}>
           <Grid container alignItems="flex-end" justifyContent="flex-end">
              <Button onClick={() => setIsOpen(!isOpen)}>Create client</Button>
           </Grid>
         </Grid>
       </Grid>
     </Container>
   );
 }

 export default TableProofContainer;
